import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogContent,
} from "@mui/material";
import { MdCheckCircle } from "react-icons/md";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import userData from "../../services/userData";
import { closeFeedback } from "../../GlobalRedux/Features/GlobalStateSlice";

const EvaluateFeedback = () => {
  const dispatch = useDispatch();
  const [evaluateFeedbackSubmitted, setEvaluateFeedbackSubmitted] =
    useState(false);
  const [feedback, setFeedback] = useState({ q1: null, q2: null, q3: "" });
  const colors = ["#FF0000", "#FF6F00", "#FFEB3B", "#8BC34A", "#4CAF50"]; // Color scheme for radio buttons

  useEffect(() => {
    // Check if feedback was submitted earlier
    setEvaluateFeedbackSubmitted(false);
  }, []);

  const isSaveEnabled = feedback.q1 !== null && feedback.q2 !== null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [name]: value,
    }));
  };

  const saveFeedback = async () => {
    setEvaluateFeedbackSubmitted(true);
    const points = (feedback.q1 || 0) + (feedback.q2 || 0);

    const token = Cookies.get("token");

    const evaluateFeedbackData = {
      evaluate: {
        question1: {
          text: "How would you rate the features of the Get Advice section?",
          answer: feedback.q1,
        },
        question2: {
          text: "Did you find the spider map?",
          answer: feedback.q2,
        },
        question3: {
          text: "Did you find any flaws in this section and are there any suggestions to improve this section?",
          answer: feedback.q3,
        },
        question4: {
          text: "Are there any other suggestions you would like to add for this section?",
          answer: feedback.q4,
        },
      },
      points: points,
      token,
    };

    try {
      const res = await userData.storeFeedback(
        "evaluate",
        evaluateFeedbackData
      );
      console.log("Data submitted successfully:", res.data);
      setTimeout(() => {
        dispatch(closeFeedback("evaluate"));
      }, 1000);
    } catch (error) {
      console.error("Error storing feedback:", error);
    }
  };

  const evaluate = useSelector((state) => state.GlobalState.evaluate);

  return (
    <Dialog open={Boolean(evaluate)} onClose={() => dispatch(closeFeedback("evaluate"))}>
      <DialogContent>
        {!evaluateFeedbackSubmitted ? (
          <Card elevation={0}>
            <CardHeader title="Evaluate CV" />
            <CardContent>
              <Grid container spacing={2}>
                {/* Question 1 */}
                <Grid item xs={12}>
                  <Typography>
                    How would you rate the features of the evaluation of your
                    CV?
                  </Typography>
                  <RadioGroup
                    name="q1"
                    value={feedback.q1}
                    onChange={handleChange}
                    row
                  >
                    {[...Array(5)].map((_, index) => (
                      <FormControlLabel
                        key={index}
                        value={index}
                        control={<Radio style={{ color: colors[index] }} />}
                        label={index.toString()}
                      />
                    ))}
                  </RadioGroup>
                </Grid>

                {/* Question 2 */}
                <Grid item xs={12}>
                  <Typography>
                    Did you find the 3x3 matrix useful?
                  </Typography>
                  <RadioGroup
                    name="q2"
                    value={feedback.q2}
                    onChange={handleChange}
                    row
                  >
                    {[...Array(5)].map((_, index) => (
                      <FormControlLabel
                        key={index}
                        value={index}
                        control={<Radio style={{ color: colors[index] }} />}
                        label={index.toString()}
                      />
                    ))}
                  </RadioGroup>
                </Grid>

                {/* Question 3 (Optional) */}
                <Grid item xs={12}>
                  <Typography>
                    Did you find any flaws in this section and are there any suggestions to improve this section?
                  </Typography>
                  <TextField
                    name="q3"
                    value={feedback.q3}
                    onChange={handleChange}
                    multiline
                    rows={2}
                    variant="outlined"
                    fullWidth
                    InputProps={{ notched: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    Are there any other suggestions you would like to add for this section?
                  </Typography>
                  <TextField
                    name="q4"
                    value={feedback.q4}
                    onChange={handleChange}
                    multiline
                    rows={2}
                    variant="outlined"
                    fullWidth
                    InputProps={{ notched: true }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <button
                onClick={saveFeedback}
                disabled={!isSaveEnabled}
                className="primary-button w-full"
              >
                Save
              </button>
            </CardActions>
          </Card>
        ) : (
          <Card className="feedback-submitted-card">
            <CardHeader
              avatar={<MdCheckCircle style={{ color: "#4caf50" }} />}
              title="Feedback Submitted"
              titleTypographyProps={{ variant: "h6" }}
            />
            <CardContent>
              <Typography>
                Thank you for your feedback! It has been successfully submitted.
              </Typography>
            </CardContent>
          </Card>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EvaluateFeedback;
