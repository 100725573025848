import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";

const markdownText = `
**Last updated: 10 Nov 2024**

## Introduction

At **PARINAAM by YUKTII Ventures**, we value your privacy. This Privacy Policy outlines how we collect, use, and protect your information.

By agreeing to our Terms of Service, you verify that you are at least 13 years old and agree to the following conditions:

1. **Consent to Process Personal Data**: 
   - I authorize the processing of personal data contained within my CV, according to GDPR (EU) 2016/679, Article 6.1(a).
   - I consent to the processing of this CV and the personal data contained within, by anyone who receives this document, in accordance with Article 6.1(a) of GDPR (EU) 2016/679.

---

## Information We Collect

- **Usage Information**: We collect data about how you interact with our website, including:
  - Pages visited
  - Features used
  - Search queries
  - Time spent on each page

- **Log Information**: When you access our platform, our servers automatically record certain information, such as:
  - IP address
  - Timestamps of access
  - Browser type and version
  - Operating system

- **Account Information**: When you create an account, we collect:
  - Name
  - Email address
  - Mobile number
  - Location

- **Contact Information**: We collect your contact information when you:
  - Contact us through our website or email
  - Subscribe to our newsletter or updates
  - Participate in surveys or contests

---

## How We Use Information

- **Usage Information**: We use this data to:
  - Analyze platform usage patterns and trends
  - Improve the functionality and user experience of our platform
  - Develop new features and content

- **Log Information**: We use this data to:
  - Monitor and troubleshoot technical issues
  - Detect and prevent fraudulent activity
  - Ensure the security and integrity of our platform

- **Account Information**: We use this data to:
  - Create and manage your account
  - Provide access to our platform and its features
  - Communicate with you about your account

- **Contact Information**: We use this data to:
  - Respond to your inquiries and requests
  - Send newsletters, updates, and promotional materials (with your consent)
  - Provide customer support

---

## Data Retention

- **Usage Information**: Stored for up to 26 months for statistical analysis.
- **Log Information**: Kept indefinitely to monitor and improve our services.
- **Account Information**: Retained for the lifetime of your account.
- **Contact Information**: Retained for ongoing communication and support, or until you request deletion.

---

## Cookies

We use cookies to enhance your experience, track usage, and manage marketing campaigns. You can manage and disable cookies through your browser settings.

---

## Your Legal Rights

You have rights regarding your personal data, including access, correction, deletion, and restriction. Contact us at [yuktii_ventures@proton.me](mailto:yuktii_ventures@proton.me) to exercise these rights.

---

## Data Deletion

Request data deletion by emailing [yuktii_ventures@proton.me](mailto:yuktii_ventures@proton.me). We will delete your information within 30 days, except for data required for legal purposes.

---

## Changes to Privacy Policy

We may update this Privacy Policy periodically. Users are requested to regularly check for privacy policy updates on our website.

---

For questions or concerns, contact us at [yuktii_ventures@proton.me](mailto:yuktii_ventures@proton.me).
`;

const GenerateMarkdown = ({ str }) => {
  return (
    <div
      className={`prose max-w-full border-2 border-primary-bold/10 p-4 rounded-md my-4 `}
    >
      <ReactMarkdown>{str}</ReactMarkdown>
    </div>
  );
};

function PrivacyPolicy({ open, setOpen }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ style: { minWidth: "80vw" } }}
    >
      <DialogTitle>
        <p className="text-xl font-bold">Privacy Policy</p>
      </DialogTitle>
      <DialogContent>
        <GenerateMarkdown str={markdownText} />
      </DialogContent>
    </Dialog>
  );
}

export default PrivacyPolicy;
