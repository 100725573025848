import axios from "axios";

 export const base2 = "https://parinaam-fastapi.yuktii.com/";
//export const base2 = "https://cae8-38-183-47-118.ngrok-free.app/"

export default axios.create({
  baseURL: base2,
  headers: {
    "Content-type": "application/json",
  },
});
