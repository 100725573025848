import React, { useEffect, useState } from "react";
import TextDiffRenderer from "../components/TextDiffRendered";
import { MdAddBox } from "react-icons/md";
import { TextareaAutosize } from "@mui/material";
import { FaDeleteLeft } from "react-icons/fa6";

const ResumeTemplate = ({
  data,
  setData,
  fontColor,
  backgroundColor,
  clickHandler,
  conflicts,
  difference,
  actionHandler,
}) => {
  const [personalDetails, setPersonalDetails] = useState({});
  const [qualifications, setQualifications] = useState([]);
  const [skills, setSkills] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [projects, setProjects] = useState([]);
  const [optionalDetails, setOptionalDetails] = useState("");

  useEffect(() => {
    console.log("CV data = ", data);
    if (data) {
      setPersonalDetails({
        name: data.name,
        location: data.location,
        email: data.email,
        phone: data.phone,
        linkedin: data.linkedin,
        portfolio: data.portfolio,
      });
      setQualifications(data?.qualifications);
      setSkills(data?.skills);
      setOptionalDetails(data?.optional);
      setExperiences(data?.experience);
      setProjects(data?.projects);
      console.log(data.candidate_summary);
    }
  }, [data]);

  const styles = {
    container: {
      fontFamily: "'Arial', sans-serif",
      maxWidth: "800px",
      margin: "20px auto",
      padding: "20px",
      // backgroundColor: backgroundColor,
      borderRadius: "10px",
      // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `2px solid ${fontColor}`,
      paddingBottom: "20px",
      marginBottom: "20px",
    },
    personalInfo: {
      flex: 1,
    },
    h1: {
      fontSize: "30px",
      color: "#333",
      marginBottom: "5px",
    },
    p: {
      margin: "0",
      fontSize: "14px",
      color: "#555",
    },
    section: {
      marginBottom: "30px",
    },
    sectionHeader: {
      fontSize: "24px",
      color: fontColor,
      borderBottom: `2px solid ${fontColor}`,
      paddingBottom: "10px",
      marginBottom: "15px",
    },
    list: {
      fontSize: "14px",
      color: "#555",
      lineHeight: 1.6,
      marginBottom: "10px",
    },
    skillsList: {
      display: "flex",
      flexWrap: "wrap",
      listStyleType: "none",
      padding: 0,
    },
    skillItem: {
      // backgroundColor: fontColor,
      color: "white",
      padding: "5px 10px",
      borderRadius: "5px",
      margin: "3px",
      fontSize: "13px",
    },
    link: {
      color: fontColor,
    },
  };

  const editPersonalDetails = (e) => {
    const { id, value } = e.target;
    console.log(id, value);
    const arr = id.split("-");
    if (arr.length < 2) return;
    setData((prev) => {
      return {
        ...prev,
        [arr[1]]: value,
      };
    });
  };

  if (!data) return null;
  return (
    <div style={styles.container}>
      {/* Personal Details Section */}
      {(personalDetails.name ||
        personalDetails.email ||
        personalDetails.phone ||
        personalDetails.linkedin ||
        personalDetails.portfolio ||
        personalDetails.location) && (
        <div id="template-info" style={styles.header}>
          <div style={styles.personalInfo}>
            <input
              style={styles.h1}
              id="template-name"
              onChange={editPersonalDetails}
              value={data.name}
              className="bg-transparent"
            />
            {data.email && data.email !== "not found" && (
              <p
                id="template-email"
                onClick={clickHandler}
                className="hover:bg-primary/10 cursor-pointer"
                style={styles.personalDetailsP}
              >
                <strong>Email:</strong> {data.email}
              </p>
            )}
            {data.phone && data.phone !== "not found" && (
              <p
                id="template-phone"
                onClick={clickHandler}
                className="hover:bg-primary/10 "
                style={styles.personalDetailsP}
              >
                <strong>Contact No:</strong> {data.phone}
              </p>
            )}
            {data.linkedin && data.linkedin !== "not found" && (
              <p
                id="template-linkedin"
                onClick={clickHandler}
                className="hover:bg-primary/10 "
                style={styles.personalDetailsP}
              >
                <strong>LinkedIn:</strong>
                <a
                  href={`${
                    !data.linkedin.startsWith("http")
                      ? "https://" + data.linkedin
                      : data.linkedin
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.linkedin}
                </a>
              </p>
            )}
            {data.portfolio && data.portfolio !== "not found" && (
              <p
                id="template-portfolio"
                onClick={clickHandler}
                className="hover:bg-primary/10 "
                style={styles.personalDetailsP}
              >
                <strong>Portfolio:</strong>
                <a
                  href={`${
                    !data.portfolio.startsWith("http")
                      ? "https://" + data.portfolio
                      : data.portfolio
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.portfolio}
                </a>
              </p>
            )}
            {data.location && data.location !== "not found" && (
              <p
                id="template-location"
                onClick={clickHandler}
                className="hover:bg-primary/10 "
                style={styles.personalDetailsP}
              >
                <strong>Location:</strong> {data.location}
              </p>
            )}
          </div>
        </div>
      )}

      <div className="grid grid-cols-2">
        <div>
          {/* Qualification Section */}
          {Array.isArray(data?.qualifications) &&
            data.qualifications.length > 0 && (
              <div
                id="template-qualification"
                className="section"
                style={styles.section}
              >
                <h2 style={styles.sectionHeader}>Qualification</h2>
                {Array.isArray(data?.qualifications) &&
                  data.qualifications.map((qualification, index) => (
                    <p
                      id={`template-qualification-${index}`}
                      style={styles.list}
                      key={index}
                      onClick={clickHandler}
                      className="hover:bg-primary/10 cursor-pointer"
                    >
                      {qualification.degree} - {qualification.institute}
                      {qualification.percentage &&
                        ` (${qualification.percentage}%)`}
                    </p>
                  ))}
              </div>
            )}

          {/* Skills Section */}
          {Array.isArray(data?.skills) && data.skills.length > 0 && (
            <div
              id="template-skills"
              className="section"
              style={styles.section}
            >
              <h2 style={styles.sectionHeader}>Skills</h2>
              <ul
                id="template-skills"
                onClick={clickHandler}
                style={styles.skillsList}
              >
                {Array.isArray(skills) &&
                  skills.map((skill, index) => (
                    <li
                      className="hover:bg-primary/10 cursor-pointer"
                      style={styles.skillItem}
                      key={index}
                    >
                      {skill}
                    </li>
                  ))}
              </ul>
            </div>
          )}

          {/* Optional Details Section */}
          {Array.isArray(data?.optional) && data.optional.length > 0 && (
            <div
              id="template-optional"
              className="section"
              style={{ ...styles.section, position: "relative" }}
            >
              <MdAddBox
                className="absolute right-0 top-0 text-green-500 hover:text-green-600 cursor-pointer"
                size={30}
                onClick={() => {
                  setData((prev) => {
                    return {
                      ...prev,
                      optional: [...prev.optional, ""],
                    };
                  });
                }}
              />
              <h2 style={styles.sectionHeader}>Optional Details</h2>
              {Array.isArray(data?.optional) &&
                data.optional.map((detail, index) => (
                  <div key={index} className="relative group">
                    <p
                      className="optional-details-description w-full"
                      style={styles.list}
                    >
                      {conflicts &&
                      difference &&
                      conflicts["optional"] &&
                      difference["optional"][index] ? (
                        <TextDiffRenderer
                          diffData={difference["optional"][index]}
                          actionHandler={actionHandler}
                          accept={`optional-${index}`}
                        />
                      ) : (
                        <TextareaAutosize
                          id={`template-optional-${index}`}
                          value={detail}
                          onChange={(e) => {
                            setData((prev) => {
                              const upd = prev.optional;
                              upd[index] = e.target.value;
                              return {
                                ...prev,
                                optional: upd,
                              };
                            });
                          }}
                          className="hover:bg-primary/10 cursor-pointer w-full bg-transparent"
                        />
                      )}

                      <button
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
                        onClick={() => {
                          setData((prev) => ({
                            ...prev,
                            optional: prev.optional.filter(
                              (_, i) => i !== index
                            ),
                          }));
                        }}
                      >
                        <FaDeleteLeft
                          size={30}
                          className="text-red-500 hover:text-red-600 translate-x-8"
                        />
                      </button>
                    </p>
                  </div>
                ))}{" "}
            </div>
          )}
        </div>

        <div className="">
          {/* Experience Section */}
          <div id="template-experience" className="relative w-full">
            {Array.isArray(data?.experience) && data.experience.length > 0 && (
              <div
                id="template-experience"
                className="section w-full"
                style={styles.section}
              >
                <ExperienceHeader setData={setData} />

                <h2 style={styles.sectionHeader}>Experience</h2>
                {Array.isArray(data?.experience) &&
                  data.experience.map((exp, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", width: "100%" }}
                    >
                      <ExperienceContainer index={index} setData={setData} />
                      <h3
                        id={`template-experience-${index}-company`}
                        onClick={clickHandler}
                        className="hover:bg-primary/10 cursor-pointer"
                        style={styles.h3}
                      >
                        {exp.position} - {exp.company}
                      </h3>
                      <p
                        id={`template-experience-${index}-duration`}
                        onClick={clickHandler}
                        style={styles.list}
                        className="hover:bg-primary/10 cursor-pointer"
                      >
                        {exp.duration}
                      </p>
                      {Array.isArray(exp?.details) &&
                        exp.details.map((detail, i) => (
                          <div
                            className="relative group w-full"
                            key={i}
                            style={styles.list}
                          >
                            {conflicts &&
                            difference &&
                            difference["experience"][index].details[i] &&
                            conflicts["experience"] === -1 ? (
                              <TextDiffRenderer
                                diffData={
                                  difference["experience"][index].details[i]
                                }
                                accept={`experience-${index}-details-${i}`}
                                actionHandler={actionHandler}
                              />
                            ) : (
                              <TextareaAutosize
                                id={`template-experience-${index}-details-${i}`}
                                className="hover:bg-primary/10 cursor-pointer bg-transparent block w-full px-1"
                                style={styles.p}
                                onChange={(e) => {
                                  setData((prev) => {
                                    const upd = { ...prev };
                                    upd.experience[index].details[i] =
                                      e.target.value;
                                    return upd;
                                  });
                                }}
                                value={detail}
                              />
                            )}

                            <button
                              className="absolute right-0 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
                              onClick={() => {
                                setData((prev) => ({
                                  ...prev,
                                  experience: prev.experience.map(
                                    (exp, expIndex) =>
                                      expIndex === index
                                        ? {
                                            ...exp,
                                            details: exp.details.filter(
                                              (_, ind) => ind !== i
                                            ),
                                          }
                                        : exp
                                  ),
                                }));
                              }}
                            >
                              <FaDeleteLeft
                                size={30}
                                className="text-red-500 hover:text-red-600 translate-x-8"
                              />
                            </button>
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
            )}
          </div>

          {/* Projects Section */}
          {Array.isArray(data?.projects) && data.projects.length > 0 && (
            <div
              className="section"
              id="template-projects"
              style={{ ...styles.section, position: "relative" }}
            >
              <ProjectHeader data={data} setData={setData} />

              <h2 style={styles.sectionHeader}>Projects</h2>
              {data.projects.map((project, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <ProjectContainer
                    data={data}
                    setData={setData}
                    index={index}
                  />
                  <h3
                    id={`template-projects-${index}-title`}
                    onClick={clickHandler}
                    className="hover:bg-primary/10 cursor-pointer"
                    style={styles.h3}
                  >
                    {project.title}{" "}
                    {project.year &&
                      project.year !== "not found" &&
                      `(${project.year})`}
                  </h3>
                  {Array.isArray(project?.description) &&
                    project.description.map((detail, i) => (
                      <div
                        className="relative group" // Add 'group' class for easy hover styling
                        key={i}
                        style={styles.list}
                      >
                        {difference &&
                        difference["projects"][index] &&
                        difference["projects"][index]["description"][i] &&
                        conflicts["projects"] === -1 ? (
                          <TextDiffRenderer
                            diffData={
                              difference["projects"][index]["description"][i]
                            }
                            accept={`projects-${index}-description-${i}`}
                            actionHandler={actionHandler}
                          />
                        ) : (
                          <TextareaAutosize
                            id={`template-projects-${index}-description-${i}`}
                            className="hover:bg-primary/10 cursor-pointer bg-transparent block w-full px-1"
                            value={detail}
                            onChange={(e) => {
                              setData((prev) => {
                                const upd = { ...prev };
                                upd.projects[index].description[i] =
                                  e.target.value;
                                return upd;
                              });
                            }}
                          />
                        )}

                        <button
                          className="absolute right-0 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
                          onClick={() => {
                            setData((prev) => ({
                              ...prev,
                              projects: prev.projects.map(
                                (project, projIndex) =>
                                  projIndex === index
                                    ? {
                                        ...project,
                                        description: project.description.filter(
                                          (_, ind) => ind !== i
                                        ),
                                      }
                                    : project
                              ),
                            }));
                          }}
                        >
                          <FaDeleteLeft
                            size={30}
                            className="text-red-500 hover:text-red-600 translate-x-8"
                          />
                        </button>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResumeTemplate;

function ExperienceHeader({ setData }) {
  return (
    <div className="absolute top-0 right-0">
      <MdAddBox
        size={30}
        className="text-green-500 hover:text-green-600 cursor-pointer"
        onClick={() => {
          setData((prevFormData) => ({
            ...prevFormData,
            experience: [
              ...prevFormData.experience,
              {
                company: "Company",
                position: "position",
                duration: "duration",
                details: ["Add details"],
              },
            ],
          }));
        }}
      />
    </div>
  );
}

function ExperienceContainer({ index, setData }) {
  return (
    <div className="absolute right-0 top-0 flex gap-1">
      <button
        className="bg-green-500 px-1 text-white"
        onClick={() => {
          setData((prev) => ({
            ...prev,
            experience: prev.experience.map((exp, i) =>
              i === index ? { ...exp, details: [...exp.details, ""] } : exp
            ),
          }));
        }}
      >
        Add detail
      </button>
      <button
        className=" text-white px-1 bg-red-500"
        onClick={() => {
          setData((prevFormData) => ({
            ...prevFormData,
            ["experience"]: prevFormData["experience"].filter(
              (_, i) => i !== index
            ),
          }));
        }}
      >
        Remove experience
      </button>
    </div>
  );
}

function ProjectHeader({ data, setData }) {
  return (
    <div className="absolute top-0 right-0">
      <MdAddBox
        size={30}
        className="text-green-500 hover:text-green-600 cursor-pointer"
        onClick={() => {
          setData((prevFormData) => ({
            ...prevFormData,
            projects: [
              ...prevFormData.projects,
              {
                title: "Project title",
                year: "project-duration",
                description: ["Add some descriptions"],
              },
            ],
          }));
        }}
      />
    </div>
  );
}

function ProjectContainer({ index, data, setData }) {
  return (
    <div className="absolute right-0 top-0 flex gap-1">
      <button
        className="bg-green-500 px-1 text-white"
        onClick={() => {
          setData((prev) => ({
            ...prev,
            projects: prev.projects.map((project, i) =>
              i === index
                ? { ...project, description: [...project.description, ""] }
                : project
            ),
          }));
        }}
      >
        Add description
      </button>
      <button
        className="bg-red-500 text-white px-1"
        onClick={() => {
          setData((prevFormData) => ({
            ...prevFormData,
            ["projects"]: prevFormData["projects"].filter(
              (_, i) => i !== index
            ),
          }));
        }}
      >
        Remove project
      </button>
    </div>
  );
}
