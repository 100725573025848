export const instructions = [
  "There will be several questions splitted into two sections, namely personal and subject-matter section.",
  "Make sure to give microphone acccess to PARINAAM in order to use interview room.",
  'Choose a question and press "Record" when you want to answer.',
  "You have to answer the question in one flow.",
  "After finishing the question, stop recording to submit.",
  "Our HIL (Human-In-the-Loop) evaluation would analyze quality of your answers and give useful feedbacks.",
  "You can download the evaluation report of your interview for your future reference.",
];

export const coverLetterPlaceholder = `I am a full-stack web developer with a passion for building innovative and user-friendly applications. My expertise lies in developing scalable and robust web solutions using various technologies, including React.js, Next.js, Vue.js, Node.js, Express.js, Flask, MongoDB, and PostgreSQL. I have a strong foundation in data structures and algorithms, which allows me to efficiently solve complex problems and optimize application performance. I am also skilled in UI/UX design and have a proven track record of delivering high-quality, engaging user interfaces. During my internship at Radical Innovations Group, I enhanced frontend UX, created interactive UI pages using Vue.js, and implemented complex controllers in Node.js and Flask to ensure seamless API integrations. I am passionate about learning and constantly expanding my knowledge base to stay at the forefront of web development trends.`;
