import React, { useEffect, useState } from "react";
import { PiNotePencilLight } from "react-icons/pi";
import userData from "../services/userData";
import dashboardData from "../services/dashboardData";
import { useLocation, useNavigate, useParams } from "react-router";
import { RiDeleteBin6Line } from "react-icons/ri";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import { Loading } from "../pages/InterviewRoom";
import { MdOutlineClose } from "react-icons/md";
import { useSelector } from "react-redux";

function Card({ main, secondary, setOpenForm, formName }) {
  return (
    <div
      onClick={() => setOpenForm(formName)}
      className=" border-primary border-[3px] bg-primary/10 p-5 rounded-2xl flex flex-col justify-between py-4 min-h-[25vh] cursor-pointer  hover:-translate-y-1 transition duration-300"
    >
      <div>
        <p className="text-primary-text font-semibold text-xl">{main}</p>
        <p>{secondary}</p>
      </div>
      <div className="flex justify-end items-end">
        <PiNotePencilLight size={40} className=" text-primary-text" />
      </div>
    </div>
  );
}

function Dashboard() {
  const params = useParams();

  const dynamic_id = useSelector(state => state.GlobalState.dynamic_id) 
  const navigate = useNavigate();
  const location = useLocation();


  const arr = [
    {
      main: "Personal info",
      secondary: "Essential contact and personal details.",
    },
    {
      main: "Qualifications",
      secondary: "Educational achievements and certifications.",
    },
    {
      main: "Skills",
      secondary: "Core competencies.",
    },
    {
      main: "Experience",
      secondary: "Professional roles and responsibilities.",
    },
    {
      main: "Projects",
      secondary: "Significant results and contributions.",
    },
    {
      main: "Optionals",
      secondary: "Additional relevant information.",
    },
  ];

  const [savingDataLoader, setSavingDataLoader] = useState(false);

  const [openForm, setOpenForm] = useState("");

  const closeForm = () => {
    setOpenForm("");
  };

  const [skillData, setSkillData] = useState("");

  const [formData, setFormData] = useState({
    summary: "",
    qualifications: [
      { degree: "", institute: "", percentage: "", description: "" },
    ],
    projects: [{ title: "", year: "", description: [""] }],
    experience: [{ company: "", position: "", duration: "", details: [""] }],
    optional: [],
    skills: [],
    name: "",
    location: "",
    email: "",
    phone: "",
    linkedin: "",
    portfolio: "",
  });

  const changeProjects = (index, field, val) => {
    setFormData((prev) => ({
      ...prev,
      projects: prev.projects.map((project, i) =>
        i === index ? { ...project, [field]: val } : project
      ),
    }));
  };

  const editCandidateSummary = async () => {
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        candidate_summary: formData.summary,
      };
      const res = await dashboardData.edit_candidate_summary(data);
      if (res) {
        // Implement the logic to hide the form and refresh the CV
        closeForm();
        getCV();
      }
    } catch (error) {
      console.error("Failed to edit candidate summary", error);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const editPersonalDetails = async (e) => {
    e.preventDefault();
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        personal_details: {
          name: formData.name,
          location: formData.location,
          email: formData.email,
          portfolio: formData.portfolio,
          contact_no: formData.phone,
          linkedin_url: formData.linkedin,
        },
      };
      const res = await dashboardData.edit_personal_details(data);
      if (res) {
        closeForm("#edit-field-personal-details");
        getCV();
      }
    } catch (err) {
      console.error("Error while editing personal details", err);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const editSkills = async () => {
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        skills: formData.skills,
      };
      const res = await dashboardData.edit_skills(data);
      if (res) {
        // Implement the logic to hide the form and refresh the CV
        closeForm("#edit-field-skills");
        getCV();
      }
    } catch (err) {
      console.error("Error while editing skills", err);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const editExperience = async () => {
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        experience: formData.experience,
      };
      const res = await dashboardData.edit_experience(data);
      if (res) {
        // Implement the logic to hide the form and refresh the CV
        closeForm("#edit-field-experience");
        getCV();
      }
    } catch (err) {
      console.error("Error while editing experience", err);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const editQualification = async (e) => {
    e.preventDefault();
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        qualification: formData.qualifications,
      };
      const res = await dashboardData.edit_qualification(data);
      if (res) {
        // Implement the logic to hide the form and refresh the CV
        closeForm("#edit-field-qualifications");
        getCV();
      }
    } catch (err) {
      console.error("Error while editing qualification", err);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const editProjects = async () => {
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        projects: formData.projects,
      };
      const res = await dashboardData.edit_projects(data);
      if (res) {
        // Implement the logic to hide the form and refresh the CV
        closeForm("#edit-field-projects");
        getCV();
      }
    } catch (err) {
      console.error("Error while editing projects", err);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const editOtherDetails = async () => {
    try {
      setSavingDataLoader(true);
      const data = {
        dynamic_id,
        other_details: formData.optional,
      };
      const res = await dashboardData.edit_other_details(data);
      if (res) {
        // Implement the logic to hide the form and refresh the CV
        closeForm("#edit-field-optional-details");
        getCV();
      }
    } catch (err) {
      console.error("Error while editing other details", err);
    } finally {
      setSavingDataLoader(false);
    }
  };

  const submitForm = (form) => {
    console.log(form);
    console.log("Form data:", JSON.parse(JSON.stringify(formData)));
    closeForm(form);
  };

  const addQualificationEntry = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      qualifications: [
        ...prevFormData.qualifications,
        { degree: "", institute: "", percentage: "", description: "" },
      ],
    }));
  };

  const addDetailsPrj = (index) => {
    console.log(`Called to add details for index: ${index}`);
    setFormData((prev) => ({
      ...prev,
      projects: prev.projects.map((project, i) =>
        i === index
          ? { ...project, description: [...project.description, ""] }
          : project
      ),
    }));
  };

  const addProjectEntry = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      projects: [
        ...prevFormData.projects,
        { title: "", year: "", description: [""] },
      ],
    }));
  };

  const addExperienceEntry = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      experience: [
        ...prevFormData.experience,
        { company: "", position: "", duration: "", details: [""] },
      ],
    }));
  };

  const addOptionalEntry = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      optional: [...prevFormData.optional, ""],
    }));
  };

  const addSkillEntry = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      skills: [...prevFormData.skills, ""],
    }));
  };

  const deleteEntry = (collection, index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [collection]: prevFormData[collection].filter((_, i) => i !== index),
    }));
  };

  const getCV = async (did = null) => {
    try {
      const res = await userData.getCV((did || dynamic_id));
      const data = res.data;
      setFormData({
        name: data.name || "",
        email: data.email || "",
        phone: data.contact_no || "",
        linkedin: data.linkedin_url || "",
        portfolio: data.portfolio || "",
        summary: data.candidate_summary || "",
        skills: data.skills || [""],
        location: data.location || "",
        projects: data.projects || [],
        experience: data.experience || [],
        qualifications: data.qualification || [],
        courses_and_certifications: data.courses_and_certifications || "",
        awards_and_achievements: data.awards_and_achievements || "",
        optional: data.other_details || [""],
      });
      console.log(data);
    } catch (err) {
      console.error("Error while fetching CV", err);
    }
  };

  useEffect(() => {
    if(location.pathname.includes('user-home') && dynamic_id){
      navigate(`/user-home/${dynamic_id}`);
      getCV(dynamic_id);
    }
  } , [dynamic_id])



  const changeQualification = (index, field, val) => {
    setFormData((prev) => {
      const data = { ...prev };
      data.qualifications[index][field] = val;
      return data;
    });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const changeExperience = (index, field, val) => {
    setFormData((prev) => ({
      ...prev,
      experience: prev.experience.map((exp, i) =>
        i === index ? { ...exp, [field]: val } : exp
      ),
    }));
  };

  return (
    <div className="mt-5">
      <Card
        main={"Candidate summary"}
        setOpenForm={setOpenForm}
        secondary={"Overview of key strengths, experience, and career goals."}
        formName="candidate-summary"
      />
      <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-3 mt-3">
        {arr.map(({ main, secondary }, i) => {
          return (
            <Card
              main={main}
              secondary={secondary}
              key={i}
              setOpenForm={setOpenForm}
              formName={main}
            />
          );
        })}
      </div>

      {/* Candidate Summary  */}

      <Dialog
        open={openForm === "candidate-summary" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">
              Candidate summary
            </p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent className=" w-full">
            <TextareaAutosize
              value={formData.summary}
              onChange={(e) =>
                setFormData((prev) => {
                  return { ...prev, summary: e.target.value };
                })
              }
              className="bg-primary/10 w-full min-h-[250px] p-5 text-lg"
            />
            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button
                  className="primary-button mx-auto"
                  onClick={editCandidateSummary}
                >
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* Personal Info */}

      <Dialog
        open={openForm === "Personal info" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">Personal info</p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent className=" w-full">
            <Box
              component="form"
              className="user-form"
              onSubmit={(e) => e.preventDefault()}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box
                className="scrollable-container py-5"
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <TextField
                  label="Name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <TextField
                  label="Location"
                  id="location"
                  value={formData.location}
                  onChange={handleChange}
                />
                <TextField
                  label="Email"
                  id="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <TextField
                  label="Phone"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
                <TextField
                  label="LinkedIn"
                  id="linkedin"
                  type="url"
                  value={formData.linkedin}
                  onChange={handleChange}
                />
                <TextField
                  label="Portfolio / Website"
                  id="portfolio"
                  type="url"
                  value={formData.portfolio}
                  onChange={handleChange}
                />
              </Box>
            </Box>

            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button
                  className="primary-button mx-auto"
                  onClick={editPersonalDetails}
                >
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* Qualifications */}

      <Dialog
        open={openForm === "Qualifications" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">
              Qualifications
            </p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent className=" w-full">
            {formData.qualifications &&
              formData.qualifications.map((qual, index) => {
                return (
                  <Box
                    component="form"
                    className="user-form"
                    onSubmit={(e) => e.preventDefault()}
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    key={index}
                  >
                    <Box
                      className="scrollable-container py-5"
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <TextField
                        label="Degree"
                        id="name"
                        value={qual.degree}
                        onChange={(e) =>
                          changeQualification(index, "degree", e.target.value)
                        }
                      />
                      <TextField
                        label="Institute Name"
                        id="institute_name"
                        value={qual.institute_name}
                        onChange={(e) =>
                          changeQualification(
                            index,
                            "institute_name",
                            e.target.value
                          )
                        }
                      />
                      <TextField
                        label="Percentage"
                        id="percentage"
                        value={qual.percentage}
                        onChange={(e) =>
                          changeQualification(
                            index,
                            "percentage",
                            e.target.value
                          )
                        }
                      />
                      <TextField
                        label="Description"
                        id="description"
                        value={qual.description}
                        onChange={(e) =>
                          changeQualification(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                      />
                    </Box>

                    <div className="flex justify-end">
                      <button
                        className="secondary-button"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteEntry("qualifications", index);
                        }}
                      >
                        <RiDeleteBin6Line size={25} />
                      </button>
                    </div>
                  </Box>
                );
              })}
            <div className="flex justify-between items-center">
              <button
                className="secondary-button"
                onClick={addQualificationEntry}
              >
                Add more
              </button>
            </div>

            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button
                  className="primary-button mx-auto"
                  onClick={editQualification}
                >
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* Skills */}

      <Dialog
        open={openForm === "Skills" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">Skills</p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>

          <DialogContent className=" w-full">
            <div className="flex flex-wrap gap-1">
              {formData.skills &&
                formData.skills.map((skill, i) => {
                  return (
                    <div
                      key={i}
                      className="flex justify-center gap-x-1 gap-y-2 items-center bg-primary/10 px-8 py-1 rounded-full"
                    >
                      <p>{skill}</p>
                      <div
                        className="rounded-full p-1 hover:bg-gray-300 cursor-pointer"
                        onClick={() =>
                          setFormData((prev) => {
                            return {
                              ...prev,
                              skills: prev.skills.filter((sk) => sk !== skill),
                            };
                          })
                        }
                      >
                        <MdOutlineClose />
                      </div>
                    </div>
                  );
                })}
            </div>

            <form className="flex items-center gap-2 my-5 justify-center">
              <TextField
                value={skillData}
                onChange={(e) => setSkillData(e.target.value)}
                onSubmit={(e) => e.preventDefault()}
              />
              <input
                type="submit"
                className="secondary-button"
                value="Add"
                onClick={(e) => {
                  e.preventDefault();
                  setFormData((prev) => ({
                    ...prev,
                    skills: [...prev.skills, skillData],
                  }));
                  setSkillData("");
                }}
              />
            </form>

            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button className="primary-button mx-auto" onClick={editSkills}>
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* Experience */}

      <Dialog
        open={openForm === "Experience" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">Experience</p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent className=" w-full">
            {formData.experience &&
              formData.experience.map((exp, index) => {
                return (
                  <Box
                    component="form"
                    className="user-form"
                    onSubmit={(e) => e.preventDefault()}
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    key={index}
                  >
                    <Box
                      className="scrollable-container py-5"
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <TextField
                        label="Company"
                        id="company"
                        value={exp.company}
                        onChange={(e) =>
                          changeExperience(index, "company", e.target.value)
                        }
                      />
                      <TextField
                        label="Position"
                        id="position"
                        value={exp.position}
                        onChange={(e) =>
                          changeExperience(index, "position", e.target.value)
                        }
                      />
                      <TextField
                        label="Duration"
                        id="duration"
                        value={exp.duration}
                        onChange={(e) =>
                          changeExperience(index, "duration", e.target.value)
                        }
                      />

                      <div className="flex flex-col gap-2">
                        <strong className="text-primary-bold">Details:</strong>
                        {exp.details &&
                          exp.details.map((d, i) => {
                            return (
                              <div className="w-full relative" key={i}>
                                <TextareaAutosize
                                  value={d}
                                  onChange={(e) =>
                                    setFormData((prev) => {
                                      const upd = { ...prev };
                                      upd.experience[index].details[i] =
                                        e.target.value;
                                      return upd;
                                    })
                                  }
                                  className="bg-primary/10 p-3 rounded-md pr-10 w-full"
                                />
                                <div
                                  onClick={() =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      experience: prev.experience.map(
                                        (exp, expIndex) =>
                                          expIndex === index
                                            ? {
                                                ...exp,
                                                details: exp.details.filter(
                                                  (_, ind) => ind !== i
                                                ),
                                              }
                                            : exp
                                      ),
                                    }))
                                  }
                                  className="rounded-full p-2 absolute top-2 right-5 hover:bg-primary/10 transition duration-300 cursor-pointer"
                                >
                                  <RiDeleteBin6Line className="" size={20} />
                                </div>
                              </div>
                            );
                          })}
                        <button
                          className="secondary-button"
                          onClick={(e) => {
                            e.preventDefault();
                            // addDetailsExp(index);
                            setFormData((prev) => ({
                              ...prev,
                              experience: prev.experience.map((exp, i) =>
                                i === index
                                  ? { ...exp, details: [...exp.details, ""] }
                                  : exp
                              ),
                            }));
                          }}
                        >
                          Add more details
                        </button>
                      </div>
                    </Box>

                    <div className="flex justify-end">
                      <button
                        className="secondary-button"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteEntry("experience", index);
                        }}
                      >
                        <RiDeleteBin6Line size={25} />
                      </button>
                    </div>
                  </Box>
                );
              })}
            <div className="flex justify-between items-center">
              <button className="secondary-button" onClick={addExperienceEntry}>
                Add more
              </button>
            </div>

            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button
                  className="primary-button mx-auto"
                  onClick={editExperience}
                >
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* Projects */}

      <Dialog
        open={openForm === "Projects" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">Projects</p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent className=" w-full">
            {formData.projects &&
              formData.projects.map((prj, index) => {
                return (
                  <Box
                    component="form"
                    className="user-form"
                    onSubmit={(e) => e.preventDefault()}
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    key={index}
                  >
                    <Box
                      className="scrollable-container py-5"
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <TextField
                        label="Title"
                        id="title"
                        value={prj.title}
                        onChange={(e) =>
                          changeProjects(index, "title", e.target.value)
                        }
                      />
                      <TextField
                        label="Year"
                        id="year"
                        value={prj.year}
                        onChange={(e) =>
                          changeProjects(index, "year", e.target.value)
                        }
                      />
                      <div className="flex flex-col gap-2">
                        <strong className="text-primary-bold">
                          Description:
                        </strong>
                        {prj.description &&
                          prj.description.map((d, i) => {
                            return (
                              <div className="w-full relative" key={i}>
                                <TextareaAutosize
                                  value={d}
                                  onChange={(e) =>
                                    setFormData((prev) => {
                                      const upd = { ...prev };
                                      upd.projects[index].description[i] =
                                        e.target.value;
                                      return upd;
                                    })
                                  }
                                  className="bg-primary/10 p-3 rounded-md pr-10 w-full"
                                />
                                <div
                                  onClick={() =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      projects: prev.projects.map(
                                        (project, projIndex) =>
                                          projIndex === index
                                            ? {
                                                ...project,
                                                description:
                                                  project.description.filter(
                                                    (_, ind) => ind !== i
                                                  ),
                                              }
                                            : project
                                      ),
                                    }))
                                  }
                                  className="rounded-full p-2 absolute top-2 right-5 hover:bg-primary/10 transition duration-300 cursor-pointer"
                                >
                                  <RiDeleteBin6Line className="" size={20} />
                                </div>
                              </div>
                            );
                          })}
                        <button
                          className="secondary-button"
                          onClick={(e) => {
                            e.preventDefault();
                            addDetailsPrj(index);
                          }}
                        >
                          Add more details
                        </button>
                      </div>
                    </Box>

                    <div className="flex justify-end">
                      <button
                        className="secondary-button"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteEntry("projects", index);
                        }}
                      >
                        <RiDeleteBin6Line size={25} />
                      </button>
                    </div>
                  </Box>
                );
              })}
            <div className="flex justify-between items-center">
              <button className="secondary-button" onClick={addProjectEntry}>
                Add more
              </button>
            </div>

            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button
                  className="primary-button mx-auto"
                  onClick={editProjects}
                >
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* Optionals */}

      <Dialog
        open={openForm === "Optionals" ? true : false}
        fullWidth
        maxWidth="lg"
      >
        <div
          className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
        >
          <DialogTitle>
            <p className="text-xl font-bold text-primary-text">
              Optional Details
            </p>
            <IconButton
              aria-label="close"
              onClick={closeForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoClose />
            </IconButton>
          </DialogTitle>
          <DialogContent className=" w-full">
            <div className="flex flex-col gap-1">
              {formData.optional &&
                formData.optional.map((op, index) => {
                  return (
                    <div
                      className="flex gap-2 items-center my-2 w-full rounded-md"
                      key={index}
                    >
                      <TextField
                        value={op}
                        onChange={(e) => {
                          setFormData((prev) => {
                            const upd = prev.optional;
                            upd[index] = e.target.value;
                            return {
                              ...prev,
                              optional: upd,
                            };
                          });
                        }}
                        className="w-full bg-primary/10"
                      />
                      <div
                        className="p-2 rounded-full hover:bg-gray-200 cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setFormData((prev) => ({
                            ...prev,
                            optional: prev.optional.filter(
                              (_, i) => i !== index
                            ),
                          }));
                        }}
                      >
                        <RiDeleteBin6Line
                          size={25}
                          className=" top-4 right-2"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>

            <button
              className="secondary-button"
              onClick={(e) => {
                e.preventDefault();
                setFormData((prev) => {
                  return {
                    ...prev,
                    optional: [...prev.optional, ""],
                  };
                });
              }}
            >
              Add more
            </button>

            <div className="flex justify-center my-5">
              {!savingDataLoader && (
                <button
                  className="primary-button mx-auto"
                  onClick={editOtherDetails}
                >
                  Save
                </button>
              )}
              {savingDataLoader && <Loading></Loading>}
            </div>
          </DialogContent>
        </div>
      </Dialog>
      
    </div>
  );
}

export default Dashboard;
