import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogContent,
} from "@mui/material";
import { MdCheckCircle } from "react-icons/md";
import Cookies from "js-cookie";
import userData from "../../services/userData";
import { closeFeedback } from "../../GlobalRedux/Features/GlobalStateSlice";
import { useDispatch, useSelector } from "react-redux";

const BuildFeedback = () => {
  const [buildFeedbackSubmitted, setBuildFeedbackSubmitted] = useState(false);
  const [feedback, setFeedback] = useState({ q1: null, q2: null, q3: "", q4: "" });
  const colors = ["red", "orange", "yellow", "green", "blue"]; // Color scheme for radio buttons

  const isSaveEnabled = feedback.q1 !== null && feedback.q2 !== null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [name]: value,
    }));
  };

  const saveFeedback = async () => {
    setBuildFeedbackSubmitted(true);
    localStorage.setItem("buildFeedbackSubmitted", JSON.stringify(true)); // Store this state in local storage
    const points = (feedback.q1 || 0) + (feedback.q2 || 0);
    const token = Cookies.get("token");
    const buildFeedbackData = {
      build: {
        question1: {
          text: "How would you rate the CV we have built for you?",
          answer: feedback.q1,
        },
        question2: {
          text: "Would you directly use this CV we have created for the role you want to apply to?",
          answer: feedback.q2,
        },
        question3: {
          text: "Could you please suggest what other features would you like to have in this tool?",
          answer: feedback.q3,
        },
        question4: {
          text: "Are there any other suggestions you would like to add for this My CV section?",
          answer: feedback.q4,
        },
      },
      points: points,
      token,
    };

    try {
      console.log("Feedback data sending = ", buildFeedbackData);
      const res = await userData.storeFeedback("build", buildFeedbackData);
      console.log("Data submitted successfully:", res.data);
      setTimeout(() => {
        dispatch(closeFeedback("build"));
      }, 1000);
    } catch (error) {
      console.error("Error storing feedback:", error);
    }
  };

  const build = useSelector((state) => state.GlobalState.build);

  const dispatch = useDispatch();

  return (
    <Dialog open={Boolean(build)} onClose={() => dispatch(closeFeedback("build"))}>
      <DialogContent>
        {!buildFeedbackSubmitted ? (
          <Card elevation={0}>
            <CardHeader title="My CV" />
            <CardContent>
              <Grid container spacing={2}>
                {/* Question 1 */}
                <Grid item xs={12}>
                  <Typography>
                    How would you rate the CV we have built for you?
                  </Typography>
                  <RadioGroup
                    name="q1"
                    value={feedback.q1}
                    onChange={handleChange}
                    row
                  >
                    {[...Array(5)].map((_, index) => (
                      <FormControlLabel
                        key={index}
                        value={index}
                        control={<Radio style={{ color: colors[index] }} />}
                        label={index.toString()}
                      />
                    ))}
                  </RadioGroup>
                </Grid>

                {/* Question 2 */}
                <Grid item xs={12}>
                  <Typography>
                    Would you directly use this CV we have created for the role
                    you want to apply to?*
                  </Typography>
                  <RadioGroup
                    name="q2"
                    value={feedback.q2}
                    onChange={handleChange}
                    row
                  >
                    {[...Array(5)].map((_, index) => (
                      <FormControlLabel
                        key={index}
                        value={index}
                        control={<Radio style={{ color: colors[index] }} />}
                        label={index.toString()}
                      />
                    ))}
                  </RadioGroup>
                </Grid>

                {/* Optional Suggestions */}
                <Grid item xs={12}>
                  <Typography>
                    Could you please suggest what other features would you like to have in this tool?
                  </Typography>
                  <TextField
                    name="q3"
                    value={feedback.q3}
                    onChange={handleChange}
                    multiline
                    rows={2}
                    variant="outlined"
                    fullWidth
                    InputProps={{ notched: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    Are there any other suggestions you would like to add for this <span className="font-semibold">My CV</span> section?
                  </Typography>
                  <TextField
                    name="q4"
                    value={feedback.q4}
                    onChange={handleChange}
                    multiline
                    rows={2}
                    variant="outlined"
                    fullWidth
                    InputProps={{ notched: true }}
                  />
                </Grid>

              </Grid>
            </CardContent>
            <CardActions>
              <button
                onClick={saveFeedback}
                disabled={!isSaveEnabled}
                className="primary-button w-full"
              >
                Save
              </button>
            </CardActions>
          </Card>
        ) : (
          <Card className="feedback-submitted-card">
            <CardHeader
              avatar={<MdCheckCircle style={{ color: "#4caf50" }} />}
              title="Feedback Submitted"
              titleTypographyProps={{ variant: "h6" }}
            />
            <CardContent>
              <Typography>
                Thank you for your feedback! It has been successfully submitted.
              </Typography>
            </CardContent>
          </Card>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default BuildFeedback;
