import React, { useEffect, useState } from "react";
import userData from "../services/userData";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";

import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { countries } from "../assets/countryData";


// import Cookies from 'js-cookie'

function FirstProfile() {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");

  const [timeIntern, setTimeIntern] = useState("");
  const [timeFull, setTimeFull] = useState("");

  const [initialData, setInitialData] = useState({
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    street: "",
    postalCode: "",
    city: "",
    state: "",
    country: "",
    showDatePicker: false,
    dob: "",
    contactNumber: "",
    Intern_years: "",
    Intern_months: "",
    FullTime_years: "",
    FullTime_months: "",
    showTooltip_1: false,
    showTooltip_2: false,
    role: "",
    role_level: "",
    role_name: "",
    src: "",
  });

  const [allow, setAllow] = useState(true);

  const [edit, setEdit] = useState({
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    street: "",
    postalCode: "",
    city: "",
    state: "",
    country: "",
    showDatePicker: false,
    dob: "",
    contactNumber: "",
    Intern_years: "",
    Intern_months: "",
    FullTime_years: "",
    FullTime_months: "",
    showTooltip_1: false,
    showTooltip_2: false,
    role: "",
    role_level: "",
    role_name: "",
    src: "",
  });

  const [error, setError] = useState("");

  const splitDuration = (timeIntern, timeFull) => {
    console.log("Splitting duration");
    const regex = /(\d+)\s+years?\s+(\d+)\s+months?/i;

    const matches_1 = timeIntern.match(regex);
    if (matches_1 && matches_1.length === 3) {
      setEdit((prevEdit) => ({
        ...prevEdit,
        Intern_years: parseInt(matches_1[1], 10),
        Intern_months: parseInt(matches_1[2], 10),
      }));
      setInitialData((prevInitialData) => ({
        ...prevInitialData,
        Intern_years: parseInt(matches_1[1], 10),
        Intern_months: parseInt(matches_1[2], 10),
      }));
    }

    const matches_2 = timeFull.match(regex);
    if (matches_2 && matches_2.length === 3) {
      setEdit((prevEdit) => ({
        ...prevEdit,
        FullTime_years: parseInt(matches_2[1], 10),
        FullTime_months: parseInt(matches_2[2], 10),
      }));
      setInitialData((prevInitialData) => ({
        ...prevInitialData,
        FullTime_years: parseInt(matches_2[1], 10),
        FullTime_months: parseInt(matches_2[2], 10),
      }));
    }
    console.log("Printing results...");
    console.log(edit);
    console.log(initialData);
  };

  const format = (dob) => {
    const day = dob.getDate();
    const month = dob.getMonth() + 1;
    const year = dob.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatExperience = (years, months) => {
    let experienceString = "";

    if (years !== "") {
      experienceString += years + " year";
      if (parseInt(years) !== 1) {
        experienceString += "s"; // pluralize "year" if necessary
      }
    }

    if (months !== "") {
      if (experienceString !== "") {
        experienceString += " ";
      }
      experienceString += months + " month";
      if (parseInt(months) !== 1) {
        experienceString += "s"; // pluralize "month" if necessary
      }
    }

    if (experienceString === "") {
      experienceString = "0 months"; // default to 0 months if no experience provided
    }

    return experienceString;
  };

  const saveProfile = async () => {
    const token = Cookies.get("token");
    if (edit.role === "1" && edit.role_name === "" && edit.role_level === "") {
      setError(
        "If you have a specific role in mind, fill the required details"
      );
    } else {
      try {
        setError("");
        const partTimeExperience = formatExperience(
          edit.Intern_years,
          edit.Intern_months
        );
        const fullTimeExperience = formatExperience(
          edit.FullTime_years,
          edit.FullTime_months
        );

        if (edit.role === "2") {
          setEdit((prevEdit) => ({
            ...prevEdit,
            role_name: "",
            role_level: "",
          }));
        }

        const data = {
          title: edit.title,
          firstName: edit.firstName,
          lastName: edit.lastName,
          middleName: edit.middleName,
          street: edit.street,
          city: edit.city,
          state: edit.state,
          country: edit.country,
          postalcode: edit.postalCode,
          dob: edit.dob,
          number: edit.contactNumber,
          partTime: partTimeExperience,
          fullTime: fullTimeExperience,
          role_name: edit.role_name,
          role_level: edit.role_level,
          token: token,
        };

        console.log("Saving data = ", data);

        const res = await userData.profile(data);
        if (res) {
          setMessage("Changes saved successfully");
          setTimeout(() => {
            navigate("/my-cv");
          }, 800);
        }
      } catch (err) {
        console.log(err);
        setError(err.response?.data?.message || "Error while saving profile");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEdit((prevEdit) => ({
      ...prevEdit,
      [name]: value,
    }));
  };

  const [exists, setExists] = useState(null);

  const checkProfile = async () => {
    try {
      const token = Cookies.get("token");
      const response = await userData.checkProfile(token);
      if (response.data.message === false) {
        setExists(false);
      } else {
        setExists(true);
      }
    } catch (err) {
      console.error("Error while checking profile", err);
      setError(err.response?.data?.message || "Error while checking profile");
    }
  };

  useEffect(() => {
    checkProfile();
  }, []);

  return (
    <div>
      <p className="text-xl font-bold text-primary-bold text-center">
        Welcome to PARINAAM!
      </p>
      {!exists && <p className="text-sm text-secondary-text text-center">Kindly fill in the fields below to get started.</p>}
      {error && (
        <p className="text-center text-red-500 font-semibold">{error}</p>
      )}
      {!exists && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            saveProfile();
          }}
          className="form-container flex flex-col gap-8 mt-5 p-5"
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Title</InputLabel>
                <Select
                  value={edit.title}
                  onChange={handleChange}
                  label="Title"
                  name="title"
                  disabled={!allow}
                  required
                >
                  <MenuItem value="Mr.">Mr.</MenuItem>
                  <MenuItem value="Ms.">Ms.</MenuItem>
                  <MenuItem value="Dr.">Dr.</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                value={edit.firstName}
                onChange={handleChange}
                name="firstName"
                disabled={!allow}
                inputProps={{ maxLength: 500 }}
                required={true}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Middle Name"
                variant="outlined"
                value={edit.middleName}
                onChange={handleChange}
                name="middleName"
                disabled={!allow}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Last Name"
                variant="outlined"
                value={edit.lastName}
                onChange={handleChange}
                name="lastName"
                required
                disabled={!allow}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                options={countries}
                getOptionLabel={(option) => option}
                value={edit.country}
                autoHighlight
                onChange={(event, newValue) => {
                  handleChange({
                    target: {
                      name: "country",
                      value: newValue,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    variant="outlined"
                    disabled={!allow}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={8} className="mt-12">
            <Grid item xs={12}>
              <Typography variant="h6">Present experience</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h6">Part time</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="years"
                variant="outlined"
                type="number"
                value={edit.Intern_years}
                onChange={handleChange}
                name="Intern_years"
                disabled={!allow}
                required
                inputProps={{ min: 0, max: 100 }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="months"
                variant="outlined"
                type="number"
                value={edit.Intern_months}
                onChange={handleChange}
                name="Intern_months"
                disabled={!allow}
                required
                inputProps={{ min: 0, max: 11 }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h6">Full time</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="years"
                variant="outlined"
                type="number"
                value={edit.FullTime_years}
                onChange={handleChange}
                name="FullTime_years"
                disabled={!allow}
                required
                inputProps={{ min: 0, max: 100 }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="months"
                variant="outlined"
                type="number"
                value={edit.FullTime_months}
                onChange={handleChange}
                name="FullTime_months"
                disabled={!allow}
                required
                inputProps={{ min: 0, max: 11 }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Do you have a specific role in mind
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioGroup
                row
                value={edit.role}
                onChange={handleChange}
                disabled={!allow}
                name="role"
              >
                <FormControlLabel
                  value="1"
                  disabled={!allow}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="2"
                  disabled={!allow}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
          </Grid>

          {edit.role === "1" && (
            <Grid container spacing={2}>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  label="Role"
                  variant="outlined"
                  value={edit.role_name}
                  onChange={handleChange}
                  name="role_name"
                  disabled={!allow}
                  inputProps={{ maxLength: 500 }}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Role level</InputLabel>
                  <Select
                    value={edit.role_level}
                    onChange={handleChange}
                    label="Role level"
                    name="role_level"
                    disabled={!allow}
                  >
                    <MenuItem value="entry level (0-3 years)">
                      entry level (0-3 years)
                    </MenuItem>
                    <MenuItem value="mid-senior level (3-10 years)">
                      mid-senior level (3-10 years)
                    </MenuItem>
                    <MenuItem value="senior level (>10 years)">
                      senior level {"(>10 years)"}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
          {error && (
            <p className="text-center text-red-500 font-semibold">{error}</p>
          )}

          <input
            type="submit"
            value="Launch PARINAAM"
            className="my-8 w-full primary-button"
          />
        </form>
      )}
      {exists && (
        <div className="mt-2 flex flex-col items-center gap-4">
          <p className="text-xl font-bold text-green-500 text-center">
            You have already registered successfully.
          </p>
          <Link to="/login" className="primary-button">
            Login
          </Link>
        </div>
      )}
    </div>
  );
}

export default FirstProfile;
