import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";

const markdownText = `

**Last updated: 10 Nov 2024**

Welcome to **PARINAAM by YUKTII Ventures!**

Thank you for using our platform and trusting us with your career goals. These Terms of Service ("Terms") govern your access to and use of PARINAAM by YUKTII Ventures' website and platform ("Services"), provided by PARINAAM by YUKTII Ventures ("us", "we", or "our").

By accessing or using our Services, you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the Services.

---

## 1. Eligibility

You must be at least 13 years old to use our Services. By agreeing to these Terms, you represent and warrant that you are at least 13 years old.

---

## 2. Accounts

When you create an account on PARINAAM by YUKTII Ventures, you are responsible for:

- Maintaining the security of your account and password.
- All activities that occur under your account.

Use a "strong" password to ensure the safety of your private information. We are not liable for any loss or damage resulting from your failure to comply with these security requirements.

---

## 3. Content

- **Your Responsibility**: You are solely responsible for the content you create, upload, post, or otherwise transmit using our Services.
- **Our Rights**: We may review and remove content that violates our policy, but we are not obligated to do so. Do not assume your content will be reviewed.
- **Prohibited Content**: You agree not to post or transmit any content that is illegal, harmful, threatening, abusive, harassing, defamatory, obscene, hateful, or otherwise objectionable.

---

## 4. Termination

We may terminate your account at any time for any reason, including but not limited to:

- Violation of these Terms.
- Inactivity.
- Suspicious activity.

Such termination will result in deactivation or deletion of your account and loss of content. No refunds are issued in such cases.

---

## 5. Privacy

Your personal information and content are governed by our Privacy Policy. By using PARINAAM by YUKTII Ventures, you agree to our Privacy Policy.

---

## 6. Limited Warranties and Liability

PARINAAM by YUKTII Ventures provides the Services "AS IS" without warranty of any kind. We are not responsible for any damages resulting from the loss of use, data, or profits in connection with the Services. 

PARINAAM by YUKTII Ventures is not liable for any leak or loss of personal data as we rely on third-party services for data storage. By agreeing to these Terms, all users acknowledge that PARINAAM by YUKTII Ventures is not liable or responsible for any inconvenience or losses due to planned or unplanned outages of third-party service providers (storage and servers). We do our best to keep users informed, but when certain events happen beyond our control, no refunds are issued in such cases.

---

## 7. Governing Law

These Terms are governed by the laws of Switzerland, without regard to conflict of law provisions. Any disputes arising from these Terms will be resolved in the courts of Switzerland.

---

## 8. Changes

We may update these Terms at any time. If a change is material, we will provide at least 15-day notice before the new terms take effect. Your continued use of the Services constitutes acceptance of the new terms.
`;

const GenerateMarkdown = ({ str }) => {
  return (
    <div
      className={`prose max-w-full border-2 border-primary-bold/10 p-4 rounded-md my-4 `}
    >
      <ReactMarkdown>{str}</ReactMarkdown>
    </div>
  );
};

function TermsAndConditions({ open, setOpen }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ style: { minWidth: "80vw" } }}
    >
      <DialogTitle>
        <div className="flex justify-between items-center w-full">
          <p className="text-xl font-bold">Terms and Conditions</p>
          <div>
            <div
              id="BadgeLight"
              style={{
                minWidth: "150px",
                maxWidth: "550px",
                padding: "15px 20px",
                borderRadius: "8px",
                backgroundColor: "white",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "Arial, sans-serif",
                border: "1px solid black",
                color: "black",
              }}
            >
              <div style={{ height: "40px" }}>
                <a
                  href="https://www.1d3.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://www.1d3.com/uploads/brand/logo/logo-black.svg"
                    alt="1D3 Digitech Logo"
                    style={{ height: "40px", marginRight: "15px" }}
                  />
                </a>
              </div>
              <div
                style={{
                  maxWidth: "350px",
                  marginTop: "10px",
                  lineHeight: "1.2",
                  fontSize: "14px",
                  color: "#606572",
                }}
              >
                <span id="badgeTextLight">
                  1D3 DIGITECH OÜ is an authorized global distributor of
                  PARINAAM by YUKTII Ventures
                </span>
              </div>
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <GenerateMarkdown str={markdownText} />
      </DialogContent>
    </Dialog>
  );
}

export default TermsAndConditions;
