import React from "react";
import { PiReadCvLogo } from "react-icons/pi";
import { BsFillPeopleFill } from "react-icons/bs";
import { IoDocumentAttachSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaCheckDouble } from "react-icons/fa6";
import { VscFeedback } from "react-icons/vsc";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaRegEdit, FaRegUserCircle } from "react-icons/fa";

function Card({ label, Icon, link }) {
  return (
    <NavLink
      to={link}
      className={`bg-secondary text-white rounded-lg p-4 
        hover:-translate-y-1
       transition-all duration-500`}
    >
      <div>
        <p className="text-xl font-bold">{label}</p>
        <div className="flex justify-end mt-4">
          <Icon size={30} />
        </div>
      </div>
    </NavLink>
  );
}

function CentralPage() {
  const data = [
    {
      label: "My CV",
      icon: PiReadCvLogo,
      link: "/my-cv",
    },
    {
      label: "Feedback",
      icon: VscFeedback,
      link: "/feedback",
    },

    {
      label: "Cover letter",
      icon: IoDocumentAttachSharp,
      link: "/cover-letter",
    },
    {
      label: "Cold emailing",
      icon: MdEmail,
      link: "/cold-email",
    },
    {
      label: "Get advice",
      icon: FaCheckDouble,
      link: "/get-advice",
    },
    {
      label: "Interview room",
      icon: BsFillPeopleFill,
      link: "/interview-room",
    },
  ];

  const name = useSelector((state) => state.GlobalState.userDetails.name);

  return (
    <div className="w-screen flex flex-col items-center gap-4 justify-center">
      <div>
        <div className="bg-secondary relative text-white rounded-lg p-4 my-4 min-h-[200px]">
          <div>
            <p className="text-xl font-bold">{`Hi, ${name}`}</p>
            <NavLink
              to="/edit-profile"
              className="flex gap-2 items-center z-10"
            >
              <p>Profile</p>
              <FaRegEdit />
            </NavLink>
          </div>
          <div className="absolute bg-gray-300 flex primary-shadow justify-center w-fit left-[50%] -translate-x-[50%] rounded-full my-auto ">
            <FaRegUserCircle size={190} />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2">
          {data.map((obj, index) => {
            const { label, icon, link } = obj;
            return <Card label={label} Icon={icon} link={link} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default CentralPage;
