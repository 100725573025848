import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import userData from "../../services/userData";
import { toast } from "react-toastify";
import { Loading } from "../../pages/InterviewRoom";
import { MdDelete } from "react-icons/md";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const AdminPortal = () => {
  const [loading, setLoading] = useState(false);
  const [pendingUsers, setPendingUsers] = useState(null);
  const [activeUsers, setActiveUsers] = useState(null);
  const [countUsers, setCountUsers] = useState({});
  const [referralEmails, setReferralEmails] = useState("");
  const [plan, setPlan] = useState({
    expiry: new Date().toISOString().split("T")[0],
    validity: 30,
    discount: 100,
  });

  const [trialPlan, setTrialPlan] = useState({
    expiry: new Date().toISOString().split("T")[0],
    validity: 10,
  });

  const [discountType, setDiscountType] = useState("percentage");

  const [verifying, setVerifying] = useState(true);
  const [bulk, setBulk] = useState("");
  const [isIndian, setIsIndian] = useState("true");
  const [validity, setValidity] = useState(6);
  const [price, setPrice] = useState(0);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [allPlans, setAllPlans] = useState(null);
  const [deletingPlan, setDeletingPlan] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  async function fetchAllPlans() {
    try {
      const res = await userData.getAllPlans();
      setAllPlans(res.data?.plans);
    } catch (err) {
      console.log(err);
      toast.error("Error fetching all plans!");
    }
  }

  useEffect(() => {
    fetchAllPlans();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const verifyAdmin = async () => {
      try {
        setVerifying(true);
        const token = Cookies.get("token");
        const res = await userData.verifyAdmin(token);
        if (res.data.success !== true) {
          navigate("/");
        }
      } catch (err) {
        console.log(err);
        navigate("/");
      } finally {
        setVerifying(false);
      }
    };

    verifyAdmin();
    fetchUsers();
  }, []);

  const selectVersion = async (version) => {
    try {
      const res = await userData.selectVersion({
        version,
        email: "adityakumar.tech24@gmail.com",
      });
      if (res.data?.success) {
        console.log("Successfully selected version: ", version);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      await fetchPendingUsers();
      await fetchAllUsers();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchPendingUsers = async () => {
    try {
      const res = await userData.getPendingUsers();
      if (res.data.users) {
        setPendingUsers(res.data.users);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updatePlan = async () => {
    try {
      const token = Cookies.get("token");
      const res = await userData.updatePlan({
        token,
        price,
        isIndian,
        validity,
      });
      if (res.data.success) {
        toast.success("Plan updated successfully");
      } else toast.error("Failed to update plan");
    } catch (err) {
      console.log(err);
      toast.error(
        "Failed to update plan: " + (err.response?.data?.message || "")
      );
    } finally {
      fetchAllPlans();
    }
  };

  const deletePlan = async () => {
    try {
      const token = Cookies.get("token");
      const res = await userData.deletePlan({
        token,
        price: deletingPlan.price,
        isIndian: deletingPlan.local,
        validity: deletingPlan.validity,
      });
      if (res.data.success) {
        toast.success("Plan updated successfully");
      } else toast.error("Failed to update plan");
    } catch (err) {
      console.log(err);
      toast.error(
        "Failed to update plan: " + (err.response?.data?.message || "")
      );
    } finally {
      setConfirmDelete(false);
      fetchAllPlans();
    }
  };

  const fetchAllUsers = async () => {
    try {
      const res = await userData.getAllUsers();
      if (res.data.users) {
        setActiveUsers(res.data.users);
        setCountUsers({
          total: res.data.totalUsers,
          subscribed: res.data.activeUsers,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const extractWords = (inputString) =>
    inputString
      .split(/\s+/)
      .map((word) => word.trim())
      .filter((word) => word.length > 0);

  const generateCodes = async () => {
    try {
      const token = Cookies.get("token");
      const data = {
        token,
        emails: extractWords(referralEmails),
        expiry: plan.expiry,
        discount: plan.discount,
        discountType,
      };
      const res = await userData.generateReferralCodes(data);
      toast.success("Referral codes generated, check browser console...");
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const generateTrialCodes = async () => {
    try {
      const token = Cookies.get("token");
      const data = {
        token,
        emails: extractWords(referralEmails),
        expiry: trialPlan.expiry,
        validity: trialPlan.validity,
      };
      const res = await userData.generateTrialCodes(data);
      toast.success("Referral codes generated, check browser console...");
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {verifying ? (
        <Loading />
      ) : (
        <div>
          <p className="admin-heading text-center text-2xl font-bold w-full">
            Admin Portal
          </p>

          <div className="flex gap-5 justify-center my-8">
            <div className="flex flex-col gap-3 p-4 rounded-xl primary-shadow items-center">
              <p className="font-bold text-lg">Total users</p>
              <p className="font-semibold">{countUsers.total}</p>
            </div>
            <div className="flex flex-col gap-3 p-4 rounded-xl primary-shadow items-center">
              <p className="font-bold text-lg">Active users</p>
              <p className="font-semibold">{countUsers.subscribed}</p>
            </div>
          </div>

          <strong className="mt-24 text-center">Select Prompt Version</strong>

          <Card className="flex justify-center gap-8 p-8 m-8">
            <Button variant="contained" onClick={() => selectVersion(1)}>
              Version 1
            </Button>
            <Button variant="contained" onClick={() => selectVersion(2)}>
              Version 2
            </Button>
          </Card>

          <Dialog
            open={openFeedback}
            onClose={() => setOpenFeedback(false)}
            maxWidth="xl"
            fullWidth
            PaperProps={{ style: { width: "90vw" } }}
          >
            <DialogContent>
              <Container>
                <AdminDashboard />
              </Container>
            </DialogContent>
          </Dialog>

          <div className="flex justify-center">
            <Button variant="contained" onClick={() => setOpenFeedback(true)}>
              Feedbacks
            </Button>
          </div>

          <div className="mt-20 bg-gray-100 my-12 p-8">
            <p className="text-xl font-bold my-5">Referral Codes</p>
            <p className="text-lg font-bold text-center">
              Bulk code generation area
            </p>
            <TextareaAutosize
              value={referralEmails}
              onChange={(e) => setReferralEmails(e.target.value)}
              className="bg-yellow-50 w-full px-4 py-2 rounded-md"
              minRows={5}
              maxRows={15}
            />
            <div className="flex justify-between items-center my-5">
              <div>
                <label htmlFor="expiry-date">Expiry date:</label>
                <input
                  id="expiry-date"
                  type="date"
                  value={plan.expiry}
                  onChange={(e) =>
                    setPlan((prev) => ({ ...prev, expiry: e.target.value }))
                  }
                />
              </div>

              <FormControl className="w-[200px] pt-2">
                <InputLabel id="discount-type-label">Discount Type</InputLabel>
                <Select
                  labelId="discount-type-label"
                  value={discountType}
                  onChange={(e) => {
                    setDiscountType(e.target.value);
                  }}
                >
                  <MenuItem value="percentage">Percentage</MenuItem>
                  <MenuItem value="flat">Flat</MenuItem>
                </Select>
              </FormControl>

              <TextField
                type="number"
                value={plan.discount}
                label="Discount"
                onChange={(e) =>
                  setPlan((prev) => ({
                    ...prev,
                    discount: parseInt(e.target.value, 10),
                  }))
                }
              />
            </div>
            <Button
              variant="contained"
              color="secondary"
              className="w-full text-center px-3 py-2 rounded-lg"
              onClick={generateCodes}
            >
              Generate codes
            </Button>
          </div>

          <div className="mt-20 bg-blue-100 my-12 p-8">
            <p className="text-xl font-bold my-5">Trial Codes</p>

            <p className="text-lg font-bold text-center">
              Bulk code generation area
            </p>
            <TextareaAutosize
              value={referralEmails}
              onChange={(e) => setReferralEmails(e.target.value)}
              className="bg-yellow-50 w-full px-4 py-2 rounded-md"
              minRows={5}
              maxRows={15}
            />
            <div className="flex justify-between items-center my-5">
              <div>
                <label htmlFor="expiry-date">Expiry date:</label>
                <input
                  id="expiry-date"
                  type="date"
                  value={trialPlan.expiry}
                  onChange={(e) =>
                    setTrialPlan((prev) => ({
                      ...prev,
                      expiry: e.target.value,
                    }))
                  }
                />
              </div>
              <TextField
                type="number"
                value={trialPlan.validity}
                label="Validity (in days)"
                onChange={(e) =>
                  setTrialPlan((prev) => ({
                    ...prev,
                    validity: parseInt(e.target.value, 10),
                  }))
                }
              />
            </div>
            <Button
              variant="contained"
              color="secondary"
              className="w-full text-center px-3 py-2 rounded-lg"
              onClick={generateTrialCodes}
            >
              Generate codes
            </Button>
          </div>

          <div className="mt-12">
            <p className="text-xl font-bold">Update / Add plans</p>
            <div className="flex justify-between gap-4 my-5">
              <div className="flex gap-2 items-center">
                <p>{isIndian ? "INR" : "EUR"}</p>
                <TextField
                  type="number"
                  label="Price"
                  value={price}
                  onChange={(e) => setPrice(parseFloat(e.target.value))}
                />
              </div>
              <TextField
                type="number"
                label="Validity (in months)"
                value={validity}
                onChange={(e) => setValidity(parseInt(e.target.value, 10))}
              />
              <FormControl fullWidth>
                <InputLabel id="isIndian-label">Is Indian?</InputLabel>
                <Select
                  labelId="isIndian-label"
                  id="isIndian"
                  value={isIndian ? "true" : "false"}
                  label="Is Indian?"
                  onChange={(e) => setIsIndian(e.target.value === "true")}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Button
              onClick={updatePlan}
              variant="contained"
              className="w-full mt-5"
            >
              Update
            </Button>
          </div>

          {allPlans && (
            <div className="flex gap-5 flex-wrap mt-5">
              {allPlans.map((plan, index) => {
                return (
                  <div
                    key={index}
                    className="border rounded-lg primary-shadow pt-5 relative p-5 flex flex-col gap-2 min-w-[200px]"
                  >
                    <MdDelete
                      onClick={() => {
                        setDeletingPlan(plan);
                        setConfirmDelete(true);
                      }}
                      title="Delete plan"
                      className="absolute top-1 right-1 text-red-500 hover:text-red-700 cursor-pointer "
                      size={25}
                    />
                    <p>
                      {(plan.local ? "INR" : "EUR") + " "}
                      {plan.price}
                    </p>
                    <p>{plan.local ? "For India" : "For outside India"}</p>
                    <p>{plan.validity + " months"}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle>Are you sure you want to delete?</DialogTitle>
        <DialogContent>
          <div className="flex justify-between">
            <Button onClick={() => setConfirmDelete(false)}>No</Button>
            <Button onClick={deletePlan}>Yes</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AdminPortal;
