import React from 'react';

const PreRenderSkeleton = ({ width, height, numberOfRows }) => {
  return (
    <div className="space-y-4 mt-2">
      {Array.from({ length: numberOfRows }).map((_, index) => (
        <div
          key={index}
          className={`bg-gray-300 animate-pulse rounded`}
          style={{ 
            width: width ? `${width}px` : '100%', 
            height: height ? `${height}px` : '100%' 
          }}
        />
      ))}
    </div>
  );
};

export default PreRenderSkeleton;
