import { FormControl, TextField } from "@mui/material";
import React, { useState } from "react";
import userData from "../services/userData";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Link } from "react-router-dom";

function ChangePassword() {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState("");

  const resendOtp = async () => {
    setError("");
    const data = { email, type: "signup" };
    try {
      const res = await userData.resendOtp(data);
      if (res.data.error) {
        setError(res.data.error);
      }
      console.log(res);
      startTimer();
    } catch (err) {
      console.log(err);
    }
  };

  const startTimer = () => {
    setTimer(60);
    const id = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(id);
          return 0;
        }
      });
    }, 1000);
    setIntervalId(id);
  };

  const changePass = async () => {
    setLoading(true);
    try {
      if (password !== confirmPassword) {
        setError("Passwords do not match");
        return;
      }
      const res = await userData.changePassword({ email, password });
      console.log(res);
      if (res.data.success) {
        setStep(4);
        setSuccess("Password changed successfully!");
      }
    } catch (e) {
      console.error(e);
      setError("Error changing password");
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setError("");
    try {
      if (!emailPattern.test(email)) {
        setError("Please enter a valid email");
        return;
      }
      const findUser = await userData.checkUserExists(email);
      if(!findUser.data?.success){
        setError('User does not exist!');
        return;
      }
      setLoading(true);
      console.log("ds");
      const res = await userData.resendOtp({ email, type: "change-password" });
      startTimer();
      console.log(res);
      if (res.data.success) {
        console.log(res);
        setError("");
        setStep(2);
      }
    } catch (err) {
      console.log(err);
      setError(err.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  function matchIsNumeric(text) {
    const isNumber = typeof text === "number";
    const isString = typeof text === "string" || text === "";
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  }

  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };

  const handleChange = (value) => {
    setError("");
    if (
      value.length === 0 ||
      (typeof value === "string" && /^\d+$/.test(value))
    ) {
      setOtp(value);
    }
  };

  const verifyOtp = async () => {
    setError("");
    try {
      const res = await userData.verifyOtp({
        email,
        type: "change-password",
        otp,
      });
      if (res.data.success) {
        setError("");
        setStep(3);
      } else {
        setError("Incorrect OTP");
      }
    } catch (e) {
      setError("Something went wrong");
      console.log(e);
    }
  };

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex flex-col gap-8">
        <div>
          <p className="font-bold text-xl">Let us recover your account!</p>
          {step === 1 && (
            <p className="font-semibold text-sm">
              Enter your registered email.
            </p>
          )}
        </div>
        {step === 1 && (
          <FormControl onSubmit={submitHandler}>
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
              variant="outlined"
            />
            <button
              onClick={submitHandler}
              value="Continue"
              className="primary-button mt-4"
            >
              Continue
            </button>
          </FormControl>
        )}

        {step === 2 && (
          <>
            <h3 className="text-center font-semibold text-lg">
              Verify Your Account
            </h3>
            <div className="text-body-2 text-secondary-text">
              We sent a verification code to{" "}
              <span className="underline italic font-semibold">{email}</span>{" "}
              <br />
              Please check your email and type the code below
            </div>
            <div>
              <MuiOtpInput
                value={otp}
                onChange={handleChange}
                length={5}
                validateChar={validateChar}
              />
            </div>
            <p className="text-secondary-text italic">
              Did not receive the code?{" "}
              <button
                className={` px-3 py-1 rounded-full underline text-blue-700 ${
                  timer > 0 && "text-gray-500"
                }`}
                onClick={resendOtp}
                disabled={timer > 0}
              >
                Resend
              </button>{" "}
              <span className="ml-1">{timer ? "in " + timer + " s" : ""}</span>
            </p>
            <button className="primary-button" onClick={verifyOtp}>
              Continue
            </button>
          </>
        )}

        {step === 3 && (
          <>
            <FormControl onSubmit={changePass}>
              <TextField
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
              />
              <TextField
                label="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="outlined"
              />
              <button
                onClick={changePass}
                value="Continue"
                className="primary-button mt-4"
              >
                Change password
              </button>
            </FormControl>
          </>
        )}

        {step === 4 && (
          <>
            <p>{success}</p>
            <Link to="/login" className="primary-button">
              Login
            </Link>
          </>
        )}
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
}

export default ChangePassword;
