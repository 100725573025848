import React, { useEffect, useState } from "react";
// import './template_styles.css';



function Template_1({ data , fontColor , backgroundColor , lines }) {
  useEffect(() => {
    console.log("CV data = ", data);
    if(data){
        setPersonalDetails({
            name: data.name,
            location: data.location,
            email: data.email,
            phone: data.phone,
            linkedin: data.linkedin,
            portfolio: data.portfolio,
        })
        setCandidateSummary(data?.summary);
        setQualification(data?.qualifications);
        setSkills(data?.skills);
        setOptionalDetails(data?.optional);
        setExperience(data?.experience);
        setProjects(data?.projects);
        console.log(data.candidate_summary);
    }

  }, [data]);
  const [personal_details , setPersonalDetails] = useState({})
  const [candidate_summary , setCandidateSummary] = useState('')
  const [Qualification , setQualification] = useState([])
  const [skills , setSkills] = useState([])
  const [optional_details , setOptionalDetails] = useState([])
  const [experience , setExperience] = useState([])
  const [projects , setProjects] = useState([])

  return (
    <div className=" min-h-[120vh] relative primary-shadow bg-white">
        
    </div>
  );


}

export default Template_1;
