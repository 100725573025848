import { Dialog, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import Markdown from "react-markdown";
import { useDispatch } from "react-redux";
import { openFeedback } from "../GlobalRedux/Features/GlobalStateSlice";

function InterviewResults({ results, setResults }) {
  const GenerateMarkdown = ({ str }) => {
    return <Markdown>{str}</Markdown>;
  };
  const [flag, setFlag] = useState(false);

  const dispatch = useDispatch();

  return (
    <Dialog open={Boolean(results)} onClose={() => setResults(null)} className="">
      <div
        className={`pt-12 bg-white flex flex-col transition duration-500 relative
        }`}
      >
        <DialogTitle>
          <p className="text-xl font-bold text-primary-text">
            Interview PARINAAM
          </p>
          <div className="flex items-center gap-1">
            <p className="text-center text-secondary-text text-sm">
              Help us improve
            </p>
            <button onClick={() => dispatch(openFeedback('interview'))} className="primary-button scale-75">Feedback</button>
          </div>
          <IconButton
            aria-label="close"
            onClick={() => setResults(null)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <IoClose />
          </IconButton>
        </DialogTitle>

        <div className="overflow-y-auto px-7 py-5">
          <div>
            <p className=" text-primary-bold my-8 ">
              Question:{" "}
              <span className=" font-semibold">{results?.question}</span>
            </p>

            <div className="scores">
              <div className="score bg-primary/10">
                <span className="label">Quality Score:</span>
                <span className="value">{results?.response1 || ""} / 5</span>
              </div>
              <div className="score bg-primary/10">
                <span className="label">Quantity Score:</span>
                <span className="value">{results?.response2 || ""} / 5</span>
              </div>
              <div className="score bg-primary/10">
                <span className="label">Relevance Score:</span>
                <span className="value">{results?.response3 || ""} / 5</span>
              </div>
            </div>

            <div>
              <p class="improvement-tips">
                <strong>Improvement Tips:</strong>
              </p>
              <GenerateMarkdown str={results?.improvementRep || ""} />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default InterviewResults;
