import React, { useEffect, useRef, useState } from "react";
import { HiOutlineUserCircle } from "react-icons/hi2";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  setDynamicId,
  setSideFlag,
  setUserDetails,
} from "../GlobalRedux/Features/GlobalStateSlice";
import userData from "../services/userData";
import { Link, NavLink } from "react-router-dom";
import GuideMe from "./GuideMe";
import { FaEquals } from "react-icons/fa6";
import { RiDashboardHorizontalFill } from "react-icons/ri";

function NavText({ text }) {
  return (
    <p className=" custom-shadow rounded-full px-12 py-2 font-semibold ">
      {text}
    </p>
  );
}

function Navbar() {
  const ref = useRef();
  const guideMeRef = useRef();

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const [username, setUsername] = useState("Guest user");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const sideFlag = useSelector((state) => state.GlobalState.sideFlag);
  const dynamic_id = useSelector((state) => state.GlobalState.dynamic_id);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth >= 1000) {
        dispatch(setSideFlag(false));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const deleteData = [
    "ai_improvements",
    "gaps",
    "interview-results",
    "matrix_score",
    "overall_score",
    "suggestions",
    "temp",
  ];

  const logoutHandler = () => {
    setOpen(false);
    deleteData.forEach((data) => {
      localStorage.removeItem(data);
    });
    Cookies.remove("token");
    navigate("/");
  };

  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        guideMeRef.current &&
        !guideMeRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    async function getDynamic() {
      try {
        const token = Cookies.get("token");
        if (token) {
          const lastDataResponse = await userData.checkLastData(token);
          let id = lastDataResponse.data.id || 0;
          if (!id) {
            const field = await userData.getNewField(token);
            id = field.data?.id;
          }
          const tempName = lastDataResponse?.data?.name || "Guest user";
          setUsername(tempName);
          dispatch(
            setUserDetails({
              name: tempName,
            })
          );
          dispatch(setDynamicId(id));
        } else navigate("/");
      } catch (err) {
        console.log(err);
        navigate("/");
      }
    }
    if (location.pathname !== "/change-password") getDynamic();

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [location.pathname]);

  if (
    location.pathname === "/change-password" ||
    location.pathname === "/profile"
  ) {
    return null;
  }

  return (
    <div className="w-full mb-5">
      <div className="flex justify-between items-center px-2 py-2 bg-secondary  rounded-lg w-full">
        <p className="text-white hidden nmd:block rounded-full px-12 py-2 font-semibold whitespace-nowrap">
          PARINAAM
        </p>
        {screenWidth < 1000 && (
          <FaEquals
            size={30}
            onClick={() => {
              dispatch(setSideFlag(!sideFlag));
            }}
          />
        )}
        {/* <div className="flex gap-12">
          <Link
            to="/interview-room"
            className={`font-semibold px-4 py-1 rounded-full primary-shadow ${
              location.pathname === "/interview-room" &&
              "border-2 border-primary"
            }`}
          >
            Interview room
          </Link>
          <Link
            to="/my-cv"
            className={`font-semibold px-4 py-1 rounded-full primary-shadow ${
              location.pathname === "/my-cv" && "border-2 border-primary"
            }`}
          >
            My CV
          </Link>
          <Link
            to="/feedback"
            className={`font-semibold px-4 py-1 rounded-full primary-shadow ${
              location.pathname === "/feedback" && "border-2 border-primary"
            }`}
          >
            Feedback
          </Link>
        </div> */}

        <div className="flex gap-4 items-center">
          <NavLink
            to="/dashboard"
            className={`px-3 py-2 rounded-md flex gap-2 items-center text-white hover:bg-white hover:text-secondary transition-all duration-300`}
          >
            <RiDashboardHorizontalFill />
            <p>Dashboard</p>
          </NavLink>{" "}
          <div
            ref={ref}
            className="rounded-lg p-2  transition-all duration-300 cursor-pointer relative"
            onClick={() => setOpen(true)}
          >
            <HiOutlineUserCircle
              className="text-white rounded-full overflow-hidden transition-all duration-500 hover:text-secondary hover:bg-white"
              size={40}
            />
            {open && (
              <div
                onMouseLeave={() => setOpen(false)}
                className="z-[1000000] glassy-bg flex flex-col gap-3 font-semibold absolute right-0 top-12 rounded-lg  px-8 py-3"
              >
                <NavLink
                  to={`/my-cv`}
                  className="whitespace-nowrap hover:bg-primary hover:text-white transition duration-300 px-3 py-2 rounded-lg"
                >
                  My CV
                </NavLink>

                <NavLink
                  to="/edit-profile"
                  className="whitespace-nowrap hover:bg-primary hover:text-white transition duration-300 px-3 py-2 rounded-lg"
                >
                  Profile
                </NavLink>
                <div
                  className="whitespace-nowrap hover:bg-primary hover:text-white transition duration-300 px-3 py-2 rounded-lg"
                  onClick={logoutHandler}
                >
                  Logout
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
