import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import userData from "../services/userData";
import { Audio } from "react-loader-spinner";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setDynamicId } from "../GlobalRedux/Features/GlobalStateSlice";

export const Divider = () => {
  return <div className="w-full h-[1px] bg-gray-200"></div>;
};

function Login() {
  const [hide, setHide] = useState(true);
  const [captcha_id, setCaptchaId] = useState(null);
  const [captcha, setCaptcha] = useState(null);
  const [captcha_loading, setCaptchaLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [verifyCaptcha, setVerifyCaptcha] = useState("");
  const [loading, setLoading] = useState(false);

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  async function getCaptcha() {
    setCaptchaLoading(true);
    await userData
      .getCaptcha()
      .then((response) => {
        setCaptcha(`data:image/svg+xml;base64,${btoa(response.data.captcha)}`);
        setCaptchaId(response.data.id);
      })
      .catch((error) => {
        console.error("Error fetching CAPTCHA:", error);
      })
      .finally(() => {
        setCaptchaLoading(false);
      });
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function login() {
    if (!email) {
      setError("Please fill the required details correctly");
    } else if (!validateEmail(email)) {
      setError("Please enter a valid email");
    } else if (!password || password.length < 8) {
      setError("Please fill the required details correctly");
    } else if (!verifyCaptcha) {
      setError("Please enter the captcha");
    } else {
      try {
        setLoading(true);
        setError("");
        const data = {
          captcha: verifyCaptcha,
          id: captcha_id,
        };
        const response = await userData.verifyCaptcha(data);

        if (response.data.error) {
          setError(response.data.error);
          setVerifyCaptcha("");
          setCaptcha(
            `data:image/svg+xml;base64,${btoa(response.data.captcha)}`
          );
          setCaptchaId(response.data.id);
        } else {
          if (response.data.result === true) {
            const loginData = {
              email,
              password,
            };
            const res = await userData.login(loginData);
            if (res.data.error) {
              setError(res.data.error);
            } else {
              setError("");
              const { token } = res.data;
              Cookies.set("token", token, { expires: 0.29 });
              const profileResponse = await userData.checkProfile(token);
              if (profileResponse.data.error) {
                setError(profileResponse.data.error);
              } else {
                if (profileResponse.data.message === false) {
                  console.log("Pushing router to profile");
                  navigate("/profile");
                } else {
                  const token = Cookies.get("token");
                  if (token) {
                    const lastDataResponse = await userData.checkLastData(
                      token
                    );
                    const id = lastDataResponse.data.id || 0;
                    dispatch(setDynamicId(id));
                    if (id) {
                      navigate(`/my-cv`);
                    } else {
                      navigate("/my-cv");
                    }
                  }
                }
              }
            }
          } else {
            setError("Invalid Captcha");
            setVerifyCaptcha("");
            getCaptcha();
          }
        }
      } catch (err) {
        console.log(err);
        setError(
          err.response?.data?.message ||
            "Internal Server Error, please try again later."
        );
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    getCaptcha();
  }, []);

  return (
    <div className="flex justify-center">
      <div className="w-full min-h-[100vh] flex gap-2 nmd:grid nmd:grid-cols-2 mx-auto overflow-y-auto">
        <div className="hidden h-full nmd:block rounded-2xl primary-shadow">
          <img src="/Butterfly_colour_nobg.png" className="overflow-hidden aspect-auto"></img>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            login();
          }}
          className="bg-white px-4 sm:px-10 py-10 flex flex-col gap-5 col-span-1"
        >
          <div className="flex flex-col items-center">
            <p className="font-bold text-3xl">Welcome back!</p>
            <p className="text-secondary-text">Please login to continue</p>
          </div>
          <div className="flex flex-col gap-5">
            <TextField
              label="Email"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <div className="w-full relative">
              <div className="absolute right-2 top-4 cursor-pointer z-10 bg-white">
                {!hide && (
                  <IoEyeOffOutline size={25} onClick={() => setHide(!hide)} />
                )}
                {hide && (
                  <IoEyeOutline size={25} onClick={() => setHide(!hide)} />
                )}
              </div>
              <TextField
                label="Password"
                variant="outlined"
                type={hide ? "password" : "text"}
                className="w-full"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
          </div>
          <div className="flex flex-col my-5 gap-2">
            <div className="flex gap-3 items-center justify-center">
              {!captcha_loading && <img src={captcha} alt="Captcha" />}
              {!captcha_loading && (
                <div
                  className="rounded-full p-2 cursor-pointer hover:bg-gray-200"
                  onClick={getCaptcha}
                >
                  <IoMdRefresh size={25} color="blue" />
                </div>
              )}
              {captcha_loading && (
                <div className="flex items-center gap-3">
                  <Audio height={40} width={40} color="blue" />
                  <p>Loading Captcha...</p>
                </div>
              )}
            </div>
            <div className="flex justify-center mt-5">
              <TextField
                label="Enter captcha"
                variant="outlined"
                onChange={(e) => setVerifyCaptcha(e.target.value)}
                value={verifyCaptcha}
              />
            </div>
            <p className=" text-red-500 font-semibold text-center mt-3">
              {error ? error + "!" : ""}
            </p>
          </div>
          <Divider />
          <input
            type="submit"
            disabled={loading}
            value="Login"
            className="primary-button"
          />
          <div>
            <div className="flex gap-1 items-center">
              <p>Do not have an account?</p>
              <Link to="/register" className="text-blue-600 underline">
                Register
              </Link>
            </div>
            <div className="flex gap-1 items-center">
              <p>Forgot your password?</p>
              <Link to="/change-password" className="text-blue-600 underline">
                Click here
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
