import React, { useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { TextField, Button, Box } from "@mui/material";
import userData from "../services/userData";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Loading } from "../pages/InterviewRoom";
import { useNavigate } from "react-router";

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function PricingCard({ plan, buyHandler }) {
  const { validity, price, local, originalPrice } = plan;

  return (
    <div className=" bg-white flex flex-col items-center gap-5 p-5 min-w-[250px] primary-shadow rounded-xl hover:scale-[1.02] transition-all duration-500">
      <div className="flex flex-col gap-5">
        <p className="font-semibold text-lg text-center">
          {validity + " Months"}
        </p>
        <p className="text-gray-500 font-semibold flex justify-between">
          <span className="mr-2">Original price:</span>
          <span>
            {local ? "₹ " : "€ "}
            {originalPrice || price}
          </span>
        </p>
        {price && originalPrice && price < originalPrice && (
          <p className="font-semibold text-green-500 flex justify-between">
            <span className="mr-2">Discount:</span>
            <span>
              {local ? "₹ " : "€ "}
              {originalPrice - price}
            </span>
          </p>
        )}
        <p className="font-bold text-3xl text-center">
          <span className="text-secondary font-semibold text-base mr-2">
            Total:{" "}
          </span>

          <span className="mr-1">
            {local ? "₹ " : "€ "}
            {price}
          </span>
          <span className="text-secondary text-sm">{` + ${
            local ? "GST" : "VAT"
          } `}</span>
        </p>
      </div>
      <button
        onClick={buyHandler}
        className="w-full px-3 py-2 rounded-md bg-secondary hover:bg-secondary/70 transition-all duration-300 text-white font-semibold"
      >
        Buy
      </button>
    </div>
  );
}

function Pricing() {
  const buyHandler = async (e) => {
    // Handle purchase

    try {
      const token = Cookies.get("token");
      const res = await userData.getSubscriptionDetails({ token });
      const existingPlan = {
        subscription: res.data.subscription,
        expiry: res.data.expiry,
      };
      console.log("User plan = ", existingPlan);
      if (existingPlan.subscription) {
        toast.error(
          `You already have an active plan, current plan will expire on: ${existingPlan.expiry}`
        );
        return;
      }
    } catch (err) {
      console.log(err);
    }

    console.log("Purchase request: ", e);
  };

  const data = [
    {
      title: "Half yearly plan",
      price: "$11",
      benefits: ["Benefit 1", "Benefit 2", "Benefit 3"],
    },
    {
      title: "Yearly plan",
      price: "$18",
      benefits: ["Benefit 1", "Benefit 2", "Benefit 3"],
    },
  ];

  const [referralCode, setReferralCode] = useState("");

  const [loadingPlan, setLoadingPlan] = useState(false);
  const [activatingPlan, setActivatingPlan] = useState(false);

  const [referralPlan, setReferralPlan] = useState(null);

  const [plans, setPlans] = useState(null);

  const [step, setStep] = useState(1);

  const handleChange = (event) => {
    setReferralCode(event.target.value);
  };

  const handleSubmit = async (event) => {
    const isReferralCodeApplied = event.target.id !== "no-code-plans";
    if (event) event.preventDefault();
    try {
      if (!referralCode) {
        setStep(3);
      }
      setLoadingPlan(true);
      const token = Cookies.get("token");
      const data = {
        token,
        code: isReferralCodeApplied ? referralCode : "",
      };
      const res = await userData.fetchPlans(data);
      if (res.data?.planType === "trial") {
        const { expiry, validity } = res.data;
        setReferralPlan({ expiry, validity });
        toast.success("You got a free trial plan.");
        setStep(2);
        return;
      }
      if (isReferralCodeApplied) {
        toast.success("Referral code validated successfully");
      }
      setPlans(res.data?.plans);
      console.log("Fetched plans = ", res);
      setStep(3);
    } catch (err) {
      console.log(err);
      if (isReferralCodeApplied)
        toast.error(
          err.response?.data?.message || "Failed to verify referral code."
        );
    } finally {
      setLoadingPlan(false);
    }
  };

  const navigate = useNavigate();

  const activateTrialPlan = async function () {
    try {
      setActivatingPlan(true);
      const token = Cookies.get("token");
      const data = {
        token,
        code: referralCode,
      };
      const res = await userData.useReferralCode(data);
      if (res.data?.success) {
        toast.success(res.data?.message || "Trial plan activated successfully");
        setTimeout(() => navigate("/dashboard"), 2500);
      }
    } catch (err) {
      console.log(err);
      toast.error(
        err.response?.data?.message || "Error activating subscription plan"
      );
    } finally {
      setActivatingPlan(false);
    }
  };

  return (
    <div>
      <div className="pricing-gradient relative py-10 flex flex-col items-center">
        <p className="text-2xl font-bold">Choose a plan</p>
        <p className="">Get access to all the exciting features.</p>
      </div>
      {step === 3 && (
        <div className="pb-10">
          <div className="flex justify-center gap-6 -translate-y-[10%]">
            {plans &&
              Array.isArray(plans) &&
              plans.map((plan, index) => {
                return (
                  <PricingCard
                    key={index}
                    plan={plan}
                    buyHandler={buyHandler}
                  />
                );
              })}
            {!plans && (
              <div className="flex gap-2 items-center h-[80vh]">
                <Loading />
                <p>Fetching plans..</p>
              </div>
            )}
          </div>
          <div className="flex justify-center mt-5">
            <div
              id="BadgeLight"
              style={{
                minWidth: "150px",
                maxWidth: "550px",
                padding: "15px 20px",
                borderRadius: "8px",
                backgroundColor: "white",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "Arial, sans-serif",
                border: "1px solid black",
                color: "black",
              }}
            >
              <div style={{ height: "40px" }}>
                <a
                  href="https://www.1d3.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://www.1d3.com/uploads/brand/logo/logo-black.svg"
                    alt="1D3 Digitech Logo"
                    style={{ height: "40px", marginRight: "15px" }}
                  />
                </a>
              </div>
              <div
                style={{
                  maxWidth: "350px",
                  marginTop: "10px",
                  lineHeight: "1.2",
                  fontSize: "14px",
                  color: "#606572",
                }}
              >
                <span id="badgeTextLight">
                  1D3 DIGITECH OÜ is an authorized global distributor of
                  PARINAAM by YUKTII Ventures
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="flex justify-center mt-4">
          <div className="p-5 rounded-xl primary-shadow min-w-[250px] flex flex-col gap-2">
            <p className="text-lg font-bold">Trial plan</p>
            <p>Validity: {referralPlan.validity + " days"}</p>
            <p>Expiry: {referralPlan.expiry}</p>
            <Button
              onClick={activateTrialPlan}
              variant="contained"
              color="success"
            >
              Activate plan
            </Button>
          </div>
        </div>
      )}
      {step === 1 && (
        <div className="flex justify-center gap-20 items-center">
          <div className="mt-5">
            <p className="font-bold text-xl mb-5 text-center">
              Have a referral code?
            </p>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                width: 300,
                margin: "0 auto",
                padding: 3,
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: "white",
              }}
            >
              <TextField
                label="Enter your referral code"
                variant="outlined"
                value={referralCode}
                onChange={handleChange}
                fullWidth
                required
              />
              {!loadingPlan && (
                <button
                  type="submit"
                  fullWidth
                  className="w-full px-3 py-2 rounded-md bg-secondary hover:bg-secondary/70 transition-all duration-300 text-white font-semibold"
                >
                  Submit
                </button>
              )}
              {loadingPlan && (
                <div className="flex justify-center items-center gap-2">
                  <Loading />
                  Verifying your referral code
                </div>
              )}
            </Box>
          </div>
          <div className="bg-gray-300 w-1 h-[150px]"></div>
          <div>
            <button
              id="no-code-plans"
              onClick={handleSubmit}
              className="w-full px-3 py-2 rounded-md bg-secondary hover:bg-secondary/70 transition-all duration-300 text-white font-semibold "
            >
              Continue without code
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Pricing;
