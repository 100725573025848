import { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useLocation, useNavigate, useParams } from "react-router";
import Cookies from "js-cookie";
import userData from "../services/userData";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Lottie from "react-lottie-player";
import loaderJson from "../assets/loader.json";
import { io } from "socket.io-client";
import { base2 } from "../http_2";

const getActivity = (service, method) => {
  if (method === 1) {
    if (service === "improve") return "Improved CV based on it's own merit";
    else return "Evaluated CV based on it's own merit";
  } else if (method === 2) {
    if (service === "improve") return "Improved CV based on job role";
    else return "Evaluated CV based on job role";
  } else {
    if (service === "improve")
      return "Improved CV based on job role and desciption";
    else return "Evaluated CV based on job role and desciption";
  }
};

export default function FileUpload() {
  
  const fileTypes = ["PDF"];
  const [file, setFile] = useState(null);
  const [jobRole, setJobRole] = useState("");
  const [roleLevel, setRoleLevel] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [error, setError] = useState("");
  const [progress, setProgress] = useState("Uploading");

  const getSessionID = () => {
    return [...Array(16)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("");
  };

  const [jobDesc, setJobDesc] = useState("");
  const [sessionID, setSessionID] = useState(getSessionID());
  const [nextText, setNextText] = useState("");
  const [socket, setSocket] = useState(null);
  const [trialsRemaining, setTrialsRemaining] = useState(null);
  const [showForm, setShowForm] = useState(0);
  const navigate = useNavigate();
  const textareaRef = useRef(null);

  let updateId = 0;

  const { service, method } = useParams();

  useEffect(() => {
    getTrialsRemaining();
  }, []);

  const getTrialsRemaining = async () => {
    const token = Cookies.get("token");
    const data = { token, service };
    try {
      const res = await userData.checkFeedbackStatus(data);
      setTrialsRemaining(res.data?.trials);
      if (res.data && res.data.trials === 0 && !res.data.feedback) {
        setShowForm(1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getNumberOfPages = async (pdfFile) => {
    try {
      return 1;
    } catch (error) {
      return 0;
    }
  };

  const upload = async () => {
    console.log("Socket connection to : ", sessionID);
    if (!file) {
      setError("Please fill all the required details");
    } else if (
      (method >= 3 && !jobDesc) ||
      (method >= 2 && !jobRole) ||
      (method >= 2 && !roleLevel)
    ) {
      setError("Please fill all the required details");
    } else {
      try {
        setProgress("Uploading...");
        setError("");
        const token = Cookies.get("token");
        const getIdres = await userData.getId(token);
        const id = getIdres.data.id;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", id);

        if (method >= 2) {
          formData.append("job_role", jobRole);
          formData.append("role_level", roleLevel);
        }
        if (method >= 3) {
          formData.append("job_description", jobDesc);
        }
        formData.append("session_id", sessionID);
        formData.append("option", method);

        const activity = getActivity(service, method);
        console.log(
          token,
          file,
          id,
          jobRole,
          roleLevel,
          jobDesc,
          sessionID,
          method,
          activity
        );

        if (service === "improve") {
          formData.append("activity", activity);
          setLoading(true);
          const data = { id: id };
          const ifID = await userData.idCheck(data);
          if (ifID.data.response) {
            const res_2 = await userData.upload_2(formData);
            setLoading(false);
            setId(res_2.data.dynamic_id);
            if (!res_2.data.ai_improvements) {
              setError("Something went wrong");
              return;
            }
            localStorage.setItem(
              "ai_improvements",
              JSON.stringify(res_2.data.ai_improvements)
            );
            navigate(`/result/improve`);
          } else {
            const res = await userData.upload(formData);
            setLoading(false);
            setId(res.data.dynamic_id);
            if (!res.data.ai_improvements) {
              setError("Something went wrong");
              return;
            }
            localStorage.setItem(
              "ai_improvements",
              JSON.stringify(res.data.ai_improvements)
            );
            navigate(`/result/improve`);
          }
        } else {
          formData.append("activity", activity);
          setLoading(true);
          const data = { id: id };
          const ifID = await userData.idCheck(data);
          if (ifID.data.response) {
            const res_2 = await userData.upload_2(formData);
            setLoading(false);
            setId(res_2.data.dynamic_id);
            if (
              !res_2.data.score ||
              !res_2.data.gaps ||
              !res_2.data.overall_score ||
              !res_2.data.suggestions
            ) {
              setError("Something went wrong");
              return;
            }
            localStorage.setItem(
              "matrix_score",
              JSON.stringify(res_2.data.score)
            );
            localStorage.setItem("gaps", JSON.stringify(res_2.data.gaps));
            localStorage.setItem(
              "overall_score",
              JSON.stringify(res_2.data.overall_score)
            );
            localStorage.setItem(
              "suggestions",
              JSON.stringify(res_2.data.suggestions)
            );
            navigate(`/result/evaluate`);
          } else {
            const res = await userData.upload(formData);
            console.log(res);
            setLoading(false);
            setId(res.data.dynamic_id);
            if (
              !res.data.score ||
              !res.data.gaps ||
              !res.data.overall_score ||
              !res.data.suggestions
            ) {
              setError("Something went wrong");
              return;
            }
            localStorage.setItem(
              "matrix_score",
              JSON.stringify(res.data.score)
            );
            localStorage.setItem("gaps", JSON.stringify(res.data.gaps));
            localStorage.setItem(
              "overall_score",
              JSON.stringify(res.data.overall_score)
            );
            localStorage.setItem(
              "suggestions",
              JSON.stringify(res.data.suggestions)
            );
            navigate(`/result/evaluate`);
          }
        }
      } catch (e) {
        console.log("Error: %% ", e);
        setError(e.response?.data?.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    }
  };

  const fileChangeHandler = (file) => {
    setError("");
    setFile(file);
  };

  const changeText = (nextData) => {
    setNextText(nextData + ".");
    console.log("Changing text from ", progress, " to ", nextData);
    animateTextChange(nextData + ".");
  };

  const animateTextChange = (nextData) => {
    const textarea = textareaRef.current;
    console.log(textarea);
    if (!textarea) return;
    textarea.classList.add("slide-up");

    setTimeout(() => {
      setProgress(nextData);
      textarea.classList.remove("slide-up");
      textarea.classList.add("slide-in");

      setTimeout(() => {
        textarea.classList.remove("slide-in");
      }, 500);
    }, 500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    upload(e);
  };

  useEffect(() => {
    const socket = io(base2, { autoConnect: false });
    socket.connect();
    setSocket(socket);

    socket.on("update", async (data) => {
      console.log(data);
      if (!data) return;
      const message = data.message;

      updateId = Math.max(+message[0], updateId);

      if (!Array.isArray(message)) {
        return;
      }

      for (let i = 1; i < message.length; i++) {
        console.log(message, " <--- ", updateId);
        if (updateId == message[0]) changeText(message[i]);
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
    });

    socket.emit("join_room", { session_id: sessionID });

    socket.on("joined", (data) => {
      console.log("Joined socket: ", data);
      setSessionID(data.sessionID);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      className="w-[90vw] flex flex-col gap-5 nmd:pl-5 md:pl-20 lg:pl-32 mt-8 sm:max-w-[600px] overflow-x-auto"
    >
      <div>
        <div className="my-5">
          <p className="font-bold text-xl">Upload your CV</p>
          <p className="text-secondary-text">Drag and Drop Files here</p>
        </div>
        <div className="flex justify-start">
          <FileUploader
            handleChange={fileChangeHandler}
            onSizeError={() => setError("File size should not exceed 10MB! ")}
            name="file"
            types={fileTypes}
            maxSize={10}
            className={"scale-50"}
          />
        </div>
        <p>{file ? `File name: ${file?.name}` : "no files uploaded yet"}</p>
      </div>

      {method >= 2 && (
        <div>
          <TextField
            disabled={loading}
            value={jobRole}
            onChange={(e) => setJobRole(e.target.value)}
            label="Job Role"
            variant="outlined"
            required={true}
            className="w-full"
          />
        </div>
      )}
      {method >= 2 && (
        <div>
          <InputLabel id="demo-simple-select-label">Job Level</InputLabel>
          <Select
            disabled={loading}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={roleLevel}
            label="Options"
            onChange={(e) => setRoleLevel(e.target.value)}
            className="w-full"
          >
            <MenuItem value={"entry level (0-3 years)"}>
              {"entry level (0-3 years)"}
            </MenuItem>
            <MenuItem value={"mid-senior level (3-10 years)"}>
              {"mid-senior level (3-10 years)"}
            </MenuItem>
            <MenuItem value={"senior level (>10 years)"}>
              {"senior level (> 10 years)"}
            </MenuItem>
          </Select>
        </div>
      )}
      {method >= 3 && (
        <div>
          <TextField
            disabled={loading}
            value={jobDesc}
            onChange={(e) => setJobDesc(e.target.value)}
            label="Job Description"
            variant="outlined"
            required={true}
            multiline={true}
            maxRows={10}
            className="w-full"
          />
        </div>
      )}

      {error && (
        <p className="text-red-400 font-semibold text-center">{error}</p>
      )}

      {!loading && (
        <input type="submit" className="primary-button" value="Upload" />
      )}

      {loading && (
        <div className="grid grid-cols-2 py-4 justify-between items-center w-full relative">
          <Lottie
            loop
            animationData={loaderJson}
            play
            style={{ width: 150, height: 150 }}
          />
          <p
            ref={textareaRef}
            className="w-[50%] absolute right-0 text-center my-20 transition-all duration-[500] ease-in-out text-primary-bold font-semibold text-lg"
          >
            {progress}
          </p>
        </div>
      )}
    </form>
  );
}
