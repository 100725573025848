function sanitizeForLatex2(text) {
    const latexSpecialChars = {
        'not found': '',
    };
    for (const [char, escapedChar] of Object.entries(latexSpecialChars)) {
        text = text.split(char).join(escapedChar);
    }
    return text;
}

export default function sanitizeNestedStructure2(data) {
    if (data === null || data === undefined) {
        return '';
    }
    if (typeof data === 'object' && !Array.isArray(data)) {
        const sanitizedObject = {};
        for (const [key, value] of Object.entries(data)) {
            sanitizedObject[key] = sanitizeNestedStructure2(value);
        }
        return sanitizedObject;
    } else if (Array.isArray(data)) {
        return data.map(element => sanitizeNestedStructure2(element));
    } else if (typeof data === 'string') {
        return sanitizeForLatex2(data);
    } else {
        return data;
    }
}