import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  Box,
} from "@mui/material";
import { instructions } from "../assets/interviewInstructions";
import userData from "../services/userData";
import { toast } from "react-toastify";
import { Loading } from "./InterviewRoom";
import { Audio } from "react-loader-spinner";
import Cookies from "js-cookie";
import { RiDeleteBin6Line } from "react-icons/ri";
import InterviewResults from "./InterviewResults";
import WebcamComponent from "../components/WebcamComponent";

export function Recording({ timer, stopHandler }) {
  return (
    <div className="flex gap-3 items-center justify-center">
      <Audio height={40} width={40} color="blue" />
      <p>{timer + " s left"}</p>
      <button
        onClick={stopHandler}
        className="bg-red-400 hover:bg-red-500 transition-all duration-300 px-3 py-1 rounded-md text-white font-semibold min-w-[70px]"
      >
        Stop
      </button>
    </div>
  );
}

function InterviewRoomUpdated() {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const [jobTitle, setJobTitle] = useState("");
  const [jobLevel, setJobLevel] = useState("Entry level");
  const [jobDescription, setJobDescription] = useState("");
  const [company, setCompany] = useState("");
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(5);

  const [recordedAudio, setRecordedAudio] = useState(null);
  const mediaRecorderRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [selectedResults, setSelectedResults] = useState(null);

  const [personalAddQuestion, setPersonalAddQuestion] = useState("");
  const [subjectAddQuestion, setSubjectAddQuestion] = useState("");

  const countdownRef = useRef(null);

  const handleAddQuestion = (question, setQuestion, setQuestionArr) => {
    setQuestionArr((prev) => [
      ...prev,
      {
        question,
        results: "",
        loading: false,
        recording: false,
      },
    ]);

    setQuestion("");
  };

  const [loadingQuestions, setLoadingQuestions] = useState(false);

  const [personalQuestions, setPersonalQuestions] = useState(null);
  const [subjectQuestions, setSubjectQuestions] = useState(null);

  const [interviewQuestion, setInterviewQuestion] = useState(null);

  async function getTechnicalQuestions() {
    try {
      setLoadingQuestions(true);
      const res = await userData.getTechnicalQuestions({
        jobTitle,
        jobLevel,
        jobDescription,
        companyName: company,
      });
      setPersonalQuestions(
        res.data?.personal.map((item) => {
          return {
            question: item,
            results: "",
            recording: false,
            loading: false,
          };
        })
      );
      setSubjectQuestions(
        res.data?.subject.map((item) => {
          return {
            question: item,
            results: "",
            recording: false,
            loading: false,
          };
        })
      );
    } catch (err) {
      toast.error("Error while getting technical questions");
      console.log(err);
    } finally {
      setLoadingQuestions(false);
    }
  }

  async function handleRecording(index, questionArray, setQuestionArray) {
    console.log("Func called");
    try {
      if (
        personalQuestions.filter((q) => q.loading).length > 0 ||
        subjectQuestions.filter((q) => q.loading).length > 0
      ) {
        toast.warn(
          "Previous response is being processed, please wait for a few seconds."
        );
        return;
      }
      setInterviewQuestion(questionArray[index]["question"]);
      setQuestionArray((prev) => {
        const updated = [...prev];
        updated[index].recording = true;
        return updated;
      });
      console.log("Recording started ********");
      const audioBlob = await startRecording(questionArray[index]["question"]);

      console.log(audioBlob);
      setQuestionArray((prev) => {
        const updated = [...prev];
        updated[index].recording = false;
        updated[index].loading = true;
        return updated;
      });
      const results = await sendRecording(
        questionArray[index]["question"],
        audioBlob
      );
      setQuestionArray((prev) => {
        const updated = [...prev];
        updated[index].loading = false;
        updated[index].results = results;
        return updated;
      });
    } catch (err) {
      console.log(err);
      setQuestionArray((prev) => {
        const updated = [...prev];
        updated[index].loading = false;
        return updated;
      });

      toast.error("Could not understand audio. Please try again.");
      console.log(error);
    } finally {
      setInterviewQuestion(null);
    }
  }

  const startRecording = async (selectedQuestion) => {
    if (!selectedQuestion) {
      alert("Please select a question first.");
      return;
    }
    console.log("setRecording(true)");
    setRecording(true);
    setTimer(100);
    let audioChunks = [];

    return new Promise(async (resolve, reject) => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const recorder = new MediaRecorder(stream);
        mediaRecorderRef.current = recorder;

        recorder.ondataavailable = (event) => {
          audioChunks.push(event.data);
        };

        recorder.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
          resolve(audioBlob);
        };

        recorder.start(1000);

        countdownRef.current = setInterval(() => {
          setTimer((prevTimer) => {
            if (prevTimer === 1) {
              stopRecording();
              return 0;
            }
            return prevTimer - 1;
          });
        }, 1000);
      } catch (error) {
        console.error("Error accessing audio device:", error);
        reject(error);
      }
    });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
    if (countdownRef.current) {
      clearInterval(countdownRef.current);
    }
  };

  const sendRecording = async (question, audioBlob) => {
    try {
      const token = Cookies.get("token");
      let formData = new FormData();
      const getIdres = await userData.getId(token);
      const id = getIdres.data.id;
      formData.append("question", question);
      formData.append("audio", audioBlob, "interview.wav");
      formData.append("user_id", id);
      let response = await userData.evaluateInterview(formData);
      return response.data;
    } catch (err) {
      console.error("Error sending recording:", error);
      throw new Error("Error while processing results");
    }
  };

  useEffect(() => {
    console.log("Recording changed to, ", recording);
  }, [recording]);

  const [downloading, setDownloading] = useState(false);

  const downloadHandler = async () => {
    try {
      const data = [...personalQuestions, ...subjectQuestions].filter(
        (q) => q.results
      );
      if (!data || data.length === 0) {
        toast.error("You do not have any results yet.");
        return;
      }
      setDownloading(true);
      const response = await userData.downloadInterviewReport({
        questionArray: data,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "interview_report.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.error("Error while downloading report", err);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div className="overflow-hidden h-full w-full primary-shadow">
      {(personalQuestions || subjectQuestions) && (
        <div className="top-2 w-full">
          <div className="flex relative w-full justify-end py-4 px-8">
            <button
              disabled={downloading}
              onClick={downloadHandler}
              className={`bg-secondary px-3 py-2 rounded-md text-white hover:bg-secondary/70 ${
                downloading && "hover:bg-gray-400 bg-gray-400"
              } transition-all duration-300`}
            >
              <p>Download report</p>
            </button>
          </div>
        </div>
      )}

      <div
        className={` w-[50vw] h-[85vh] fixed bottom-0 bg-blue-50 ${
          recording ? "-translate-y-4" : "translate-y-[100%]"
        } transition-all duration-1000 flex flex-col items-center gap-5 primary-shadow p-8 rounded-xl border-2 border-secondary bg-white z-[100]`}
      >
        {interviewQuestion && (
          <div className="">
            <p>
              <strong>Question: </strong>
              {interviewQuestion}
            </p>
          </div>
        )}
        <div className="w-[35vw] border-2 rounded-xl overflow-hidden ">
          {recording && <WebcamComponent />}
        </div>

        <div className="flex justify-center items-center gap-4">
          <p className="text-secondary font-semibold">{`${timer}s left`}</p>
          <button
            className="bg-red-400 hover:bg-red-500 transition-all duration-300 px-3 py-1 rounded-md text-white font-semibold min-w-[70px]"
            onClick={stopRecording}
          >
            Stop
          </button>
        </div>
      </div>

      <div
        style={{
          transform: `translateX(-${(step - 1) * 100}%)`,
        }}
        className="flex h-full w-full overflow-visible transition-all duration-500"
      >
        {/* Step 1 */}
        <div
          className={`flex-shrink-0 h-full w-[100%] flex flex-col items-center gap-11 py-8 opacity-0 ${
            step === 1 && "opacity-100"
          }`}
        >
          <p className="text-xl font-bold text-center">
            Welcome to the interview room!
          </p>
          <div>
            <p className="font-semibold">Do you have an upcoming interview?</p>
            <p className="text-secondary-text text-xs">
              Don't worry, PARINAAM has got you covered with the latest
              interview trends for your practice.
            </p>
            <div className="flex justify-between items-center p-4">
              <button
                onClick={() => navigate(-1)}
                className="border-2 border-secondary hover:text-white hover:bg-secondary px-3 py-1 rounded-md"
              >
                No, take me back
              </button>
              <button
                onClick={() => setStep(2)}
                className="bg-secondary border-2 border-secondary text-white hover:bg-secondary/70 px-3 py-1 rounded-md"
              >
                Yes
              </button>
            </div>
          </div>
        </div>

        {/* Step 2 */}
        <div
          className={`w-full flex-shrink-0 opacity-0 ${
            step === 2 && "opacity-100"
          } h-full transition duration-300 flex flex-col items-center gap-5 py-12`}
        >
          <div>
            <p className="font-semibold text-lg">
              Tell us more about the upcoming interview
            </p>
            <p className="text-sm text-secondary-text text-center">
              We will help you prepare systematically.
            </p>
          </div>

          <div className="flex flex-col gap-4 w-3/4">
            <TextField
              label="Job Title"
              variant="outlined"
              required
              fullWidth
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />

            <FormControl fullWidth variant="outlined">
              <InputLabel>Job Level</InputLabel>
              <Select
                value={jobLevel}
                onChange={(e) => setJobLevel(e.target.value)}
                required
                label="Job Level"
              >
                <MenuItem value="Entry level">Entry level</MenuItem>
                <MenuItem value="Mid-senior level">Mid-senior level</MenuItem>
                <MenuItem value="Senior level">Senior level</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Company name (optional)"
              variant="outlined"
              fullWidth
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />

            <TextField
              label="Job description (optional)"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
            />

            <button
              onClick={() => {
                if (!jobTitle) {
                  toast.error("Job title is required");
                  return;
                }
                setStep(3);
              }}
              className="bg-secondary hover:bg-secondary/70 px-3 py-1 rounded-lg text-white"
            >
              Continue
            </button>
          </div>
        </div>

        {/* Step 3 */}
        <div
          className={`px-7 flex-shrink-0 flex flex-col items-center gap-8 py-5 w-full opacity-0 ${
            step === 3 && "opacity-100"
          }`}
        >
          <div>
            <p className="text-center font-semibold text-lg">
              Interview instructions
            </p>
            <p className="text-center text-secondary-text text-sm">
              Carefully read the below instructions before proceeding.
            </p>
          </div>
          <div className="text-secondary-text border-l-2 border-secondary px-[22px] flex flex-col gap-7">
            {instructions.map((inst, index) => {
              return (
                <div key={index} className="relative">
                  <div className="absolute top-0 -left-8">
                    <div className="h-[20px] aspect-square bg-secondary text-white rounded-full relative">
                      <p className="absolute mx-auto my-auto text-center px-[5px]">
                        {index + 1}
                      </p>
                    </div>
                  </div>
                  <p>{inst}</p>
                </div>
              );
            })}
          </div>
          <button
            onClick={() => {
              setStep(4);
              getTechnicalQuestions();
            }}
            className="bg-secondary hover:bg-secondary/70 px-3 py-1 rounded-lg w-full text-white"
          >
            Launch interview room
          </button>
        </div>

        {/* Step 4 */}
        <div
          className={`px-7 flex-shrink-0 flex flex-col items-center gap-8 py-5 w-full opacity-0 ${
            step === 4 && "opacity-100"
          }`}
        >
          {loadingQuestions && (
            <div className=" w-full flex justify-center gap-3 items-center pt-24">
              <Loading />
              <p className="font-semibold text-sm">Just a moment</p>
            </div>
          )}

          {(personalQuestions || subjectQuestions) && (
            <div className="">
              <Typography>
                <p className="font-bold my-2">Personal assessment:</p>
              </Typography>
              <div className="w-[80vw] mb-16 nmd:w-full  overflow-auto">
                <table className=" w-[680px] md:w-full bg-white border border-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="py-2 px-4 border-b text-center">
                        Questions
                      </th>
                      <th className="py-2 px-4 border-b text-center">
                        Actions
                      </th>
                      <th className="py-2 px-4 border-b text-center">
                        Results
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {personalQuestions.map((q, i) => (
                      <tr key={i} className="hover:bg-gray-50">
                        <td className="py-2 px-4 border-b">{q.question}</td>
                        <td className="py-2 px-4 border-b text-center align-middle">
                          {q.recording && (
                            <Recording
                              timer={timer}
                              stopHandler={stopRecording}
                            />
                          )}
                          {q.loading && <Loading />}
                          {!q.recording && !q.loading && !q.results && (
                            <button
                              onClick={() =>
                                handleRecording(
                                  i,
                                  personalQuestions,
                                  setPersonalQuestions
                                )
                              }
                              disabled={recording}
                              className="text-secondary border-secondary border-2 rounded-md min-w-[100px] hover:bg-secondary hover:text-white transition-all duration-300 px-3 py-1"
                            >
                              Record
                            </button>
                          )}
                          {!q.recording && !q.loading && q.results && (
                            <div className="flex gap-4 items-center justify-center">
                              <button
                                onClick={() =>
                                  handleRecording(
                                    i,
                                    personalQuestions,
                                    setPersonalQuestions
                                  )
                                }
                                className="text-secondary border-secondary border-2 rounded-md min-w-[100px] hover:bg-secondary hover:text-white transition-all duration-300 px-3 py-1"
                              >
                                Retry
                              </button>
                            </div>
                          )}
                        </td>
                        <td className="py-2 px-4 border-b text-center align-middle">
                          {q.results && !q.loading ? (
                            <button
                              className="bg-secondary hover:bg-secondary/70 transition-all duration-300 text-white py-1 px-3 rounded hover:bg-green-600"
                              onClick={() => setSelectedResults(q.results)}
                            >
                              Results
                            </button>
                          ) : q.loading ? (
                            <p className="text-sm bg-primary/10 px-3 py-1 rounded-xl">
                              Processing...
                            </p>
                          ) : (
                            "NA"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: "100%",
                    maxWidth: 400,
                    margin: "auto",
                    mt: 3,
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAddQuestion(
                      personalAddQuestion,
                      setPersonalAddQuestion,
                      setPersonalQuestions
                    );
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      label="Add a Question"
                      value={personalAddQuestion}
                      required
                      onChange={(e) => setPersonalAddQuestion(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleAddQuestion(
                        personalAddQuestion,
                        setPersonalAddQuestion,
                        setPersonalQuestions
                      )
                    }
                    disabled={!personalAddQuestion.trim()}
                    sx={{ alignSelf: "flex-end" }}
                  >
                    Add Question
                  </Button>
                </Box>
              </div>

              <Typography>
                <p className="font-bold my-2">Subject-matter assessment:</p>
              </Typography>
              <div className="w-[80vw] nmd:w-full  overflow-auto">
                <table className=" w-[680px] md:w-full bg-white border border-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="py-2 px-4 border-b text-center">
                        Questions
                      </th>
                      <th className="py-2 px-4 border-b text-center">
                        Actions
                      </th>
                      <th className="py-2 px-4 border-b text-center">
                        Results
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjectQuestions.map((q, i) => (
                      <tr key={i} className="hover:bg-gray-50">
                        <td className="py-2 px-4 border-b">{q.question}</td>
                        <td className="py-2 px-4 border-b text-center align-middle">
                          {q.recording && (
                            <Recording
                              timer={timer}
                              stopHandler={stopRecording}
                            />
                          )}
                          {q.loading && <Loading />}
                          {!q.recording && !q.loading && !q.results && (
                            <button
                              onClick={() =>
                                handleRecording(
                                  i,
                                  subjectQuestions,
                                  setSubjectQuestions
                                )
                              }
                              disabled={recording}
                              className="text-secondary border-secondary border-2 rounded-md min-w-[100px] hover:bg-secondary hover:text-white transition-all duration-300 px-3 py-1"
                            >
                              Record
                            </button>
                          )}
                          {!q.recording && !q.loading && q.results && (
                            <div className="flex gap-4 items-center justify-center">
                              <button
                                onClick={() =>
                                  handleRecording(
                                    i,
                                    subjectQuestions,
                                    setSubjectQuestions
                                  )
                                }
                                className="text-secondary border-secondary border-2 rounded-md min-w-[100px] hover:bg-secondary hover:text-white transition-all duration-300 px-3 py-1"
                              >
                                Retry
                              </button>
                            </div>
                          )}
                        </td>
                        <td className="py-2 px-4 border-b text-center align-middle">
                          {q.results && !q.loading ? (
                            <button
                              className="bg-secondary hover:bg-secondary/70 transition-all duration-300 text-white py-1 px-3 rounded hover:bg-green-600"
                              onClick={() => setSelectedResults(q.results)}
                            >
                              Results
                            </button>
                          ) : q.loading ? (
                            <p className="text-sm bg-primary/10 px-3 py-1 rounded-xl">
                              Processing...
                            </p>
                          ) : (
                            "NA"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: "100%",
                    maxWidth: 400,
                    margin: "auto",
                    mt: 3,
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAddQuestion(
                      subjectAddQuestion,
                      setSubjectAddQuestion,
                      setSubjectQuestions
                    );
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      label="Add a Question"
                      value={subjectAddQuestion}
                      required
                      onChange={(e) => setSubjectAddQuestion(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleAddQuestion(
                        subjectAddQuestion,
                        setSubjectAddQuestion,
                        setSubjectQuestions
                      )
                    }
                    disabled={!subjectAddQuestion.trim()}
                    sx={{ alignSelf: "flex-end" }}
                  >
                    Add Question
                  </Button>
                </Box>
              </div>
            </div>
          )}
        </div>
      </div>
      <InterviewResults
        results={selectedResults}
        setResults={setSelectedResults}
      />
    </div>
  );
}

export default InterviewRoomUpdated;
