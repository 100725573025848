import React, { useEffect, useState } from "react";
import "./template_styles.css";
import TextDiffRenderer from "../components/TextDiffRendered";
import { TextareaAutosize } from "@mui/material";
import { MdAddBox } from "react-icons/md";
import { FaDeleteLeft } from "react-icons/fa6";

const TemplateTesing_2 = ({
  data,
  setData,
  fontColor,
  backgroundColor,
  clickHandler,
  conflicts,
  difference,
  actionHandler,
}) => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
      backgroundColor: "#FFFFFF",
    },
    resumeHeader: {
      backgroundColor: "#FFFFFF",
      padding: "10px",
      textAlign: "center",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    resumeHeaderH1: {
      margin: 0,
      fontSize: "28px",
      color: fontColor,
      fontWeight: "bold",
    },
    resumeHeaderP: {
      margin: "10px 0 0",
      fontSize: "18px",
      color: "#000000",
      textAlign: "justify",
      backgroundColor: backgroundColor,
      display: "inline-block",
      maxWidth: "100%",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    resume: {
      display: "block",
      width: "100%",
      maxWidth: "800px",
      margin: "-20px auto 0",
      padding: "20px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    section: {
      marginBottom: "10px",
      width: "100%",
    },
    sectionH2: {
      borderBottom: `2px solid ${fontColor}`,
      paddingBottom: "10px",
      marginBottom: "20px",
      fontSize: "22px",
      fontWeight: "bold",
      color: fontColor,
    },
    sectionP: {
      fontSize: "16px",
      lineHeight: "1.0",
      color: "#212529",
    },
    skillsList: {
      backgroundColor: backgroundColor,
      padding: "10px",
      marginBottom: "10px",
      borderLeft: `4px solid ${fontColor}`,
    },
    personalDetailsP: {
      backgroundColor: backgroundColor,
      padding: "10px",
      marginBottom: "10px",
      borderLeft: `4px solid ${fontColor}`,
    },
    project: {
      backgroundColor: backgroundColor,
      padding: "10px",
      marginBottom: "10px",
      borderLeft: `4px solid ${fontColor}`,
    },
    job: {
      backgroundColor: backgroundColor,
      padding: "10px",
      marginBottom: "10px",
      borderLeft: `4px solid ${fontColor}`,
    },
    optionalDetailsDescription: {
      backgroundColor: backgroundColor,
      padding: "10px",
      marginBottom: "10px",
      borderLeft: `4px solid ${fontColor}`,
    },
    strong: {
      display: "flex",
    },
    h3: {
      fontWeight: "bold",
      marginBlock: "20px",
      fontSize: "1.14em",
    },
    p: {
      marginBlock: "10px",
    },
  };

  const editPersonalDetails = (e) => {
    const { id, value } = e.target;
    console.log(id, value);
    const arr = id.split("-");
    if (arr.length < 2) return;
    setData((prev) => {
      return {
        ...prev,
        [arr[1]]: value,
      };
    });
  };

  if (!data) return null;

  return (
    <div style={styles.body} className="w-[650px]">
      <div style={styles.resumeHeader}>
        <input
          id="template-name"
          onChange={editPersonalDetails}
          className="hover:bg-primary/10 text-center cursor-pointer w-full"
          style={styles.resumeHeaderH1}
          value={data.name}
        />
        <p className="hover:bg-primary/10 w-full" style={styles.resumeHeaderP}>
          {difference &&
          difference["summary"] &&
          conflicts &&
          conflicts["summary"] === -1 ? (
            <TextDiffRenderer
              diffData={difference["summary"]}
              accept={"cs"}
              actionHandler={actionHandler}
            />
          ) : (
            <TextareaAutosize
              id="template-cs"
              className="hover:bg-primary/10 cursor-pointer w-full bg-transparent"
              value={data?.summary}
              onChange={(e) => {
                setData((prev) => {
                  return {
                    ...prev,
                    summary: e.target.value,
                  };
                });
              }}
            ></TextareaAutosize>
          )}
        </p>
      </div>
      <div style={styles.resume}>
        <div className="left-column">
          {/* Personal Details Section */}
          <div id="template-info" className="section" style={styles.section}>
            <h2 style={styles.sectionH2}>Personal Details</h2>
            {data.email && data.email !== "not found" && (
              <p
                id="template-email"
                onClick={clickHandler}
                className="hover:bg-primary/10 cursor-pointer"
                style={styles.personalDetailsP}
              >
                <strong>Email:</strong> {data.email}
              </p>
            )}
            {data.phone && data.phone !== "not found" && (
              <p
                id="template-phone"
                onClick={clickHandler}
                className="hover:bg-primary/10 "
                style={styles.personalDetailsP}
              >
                <strong>Contact No:</strong> {data.phone}
              </p>
            )}
            {data.linkedin && data.linkedin !== "not found" && (
              <p
                id="template-linkedin"
                onClick={clickHandler}
                className="hover:bg-primary/10 "
                style={styles.personalDetailsP}
              >
                <strong>LinkedIn:</strong>
                <a
                  href={`${
                    !data.linkedin.startsWith("http")
                      ? "https://" + data.linkedin
                      : data.linkedin
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.linkedin}
                </a>
              </p>
            )}
            {data.portfolio && data.portfolio !== "not found" && (
              <p
                id="template-portfolio"
                onClick={clickHandler}
                className="hover:bg-primary/10 "
                style={styles.personalDetailsP}
              >
                <strong>Portfolio:</strong>
                <a
                  href={`${
                    !data.portfolio.startsWith("http")
                      ? "https://" + data.portfolio
                      : data.portfolio
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.portfolio}
                </a>
              </p>
            )}
            {data.location && data.location !== "not found" && (
              <p
                id="template-location"
                onClick={clickHandler}
                className="hover:bg-primary/10 "
                style={styles.personalDetailsP}
              >
                <strong>Location:</strong> {data.location}
              </p>
            )}
          </div>

          {/* Qualification Section */}
          {Array.isArray(data?.qualifications) &&
            data.qualifications.length > 0 && (
              <div
                id="template-qualification"
                className="section"
                style={styles.section}
              >
                <h2 style={styles.sectionH2}>Qualification</h2>
                {Array.isArray(data?.qualifications) &&
                  data.qualifications.map((qualification, index) => (
                    <p
                      id={`template-qualification-${index}`}
                      onClick={clickHandler}
                      className="hover:bg-primary/10 cursor-pointer"
                      style={styles.personalDetailsP}
                      key={index}
                    >
                      <strong
                        id={`template-qualification-${index}`}
                        className="hover:bg-primary/10 "
                      >
                        {qualification.degree}
                        {qualification.institute_name &&
                          ` - ${qualification.institute_name}`}
                      </strong>
                      {qualification.percentage &&
                        ` (${qualification.percentage})`}
                    </p>
                  ))}
              </div>
            )}

          {/* Skills Section */}
          {Array.isArray(data?.skills) && data.skills.length > 0 && (
            <div
              id="template-skills"
              className="  section"
              style={styles.section}
            >
              <h2 style={styles.sectionH2}>Skills</h2>
              <div
                id="template-skills"
                onClick={clickHandler}
                style={styles.skillsList}
              >
                {Array.isArray(data?.skills) &&
                  data.skills.map((skill, index) => (
                    <span
                      className="hover:bg-primary/10 cursor-pointer"
                      key={index}
                    >
                      {skill}
                      {index !== data.skills.length - 1 && ", "}
                    </span>
                  ))}
              </div>
            </div>
          )}

          {/* Experience Section */}
          <div id="template-experience" className="relative w-full">
            {Array.isArray(data?.experience) && data.experience.length > 0 && (
              <div id="" className="section" style={styles.section}>
                <ExperienceHeader setData={setData} />
                <h2 style={styles.sectionH2}>Experience</h2>
                {Array.isArray(data?.experience) &&
                  data.experience.map((exp, index) => (
                    <div
                      className="job w-full"
                      style={{ ...styles.job, position: "relative" }}
                      key={index}
                    >
                      <ExperienceContainer index={index} setData={setData} />
                      <h3
                        id={`template-experience-${index}-company`}
                        onClick={clickHandler}
                        className="hover:bg-primary/10 cursor-pointer"
                        style={styles.h3}
                      >
                        {exp.company}
                        {exp.position && ` - ${exp.position}`}
                      </h3>
                      <p
                        id={`template-experience-${index}-duration`}
                        onClick={clickHandler}
                        className="hover:bg-primary/10 cursor-pointer"
                      >
                        {exp.duration}
                      </p>
                      {Array.isArray(exp?.details) &&
                        exp.details.map((detail, i) => (
                          <div className="relative group" key={i}>
                            {conflicts &&
                            difference &&
                            difference["experience"][index].details[i] &&
                            conflicts["experience"] === -1 ? (
                              <TextDiffRenderer
                                diffData={
                                  difference["experience"][index].details[i]
                                }
                                accept={`experience-${index}-details-${i}`}
                                actionHandler={actionHandler}
                              />
                            ) : (
                              <TextareaAutosize
                                id={`template-experience-${index}-details-${i}`}
                                className="hover:bg-primary/10 cursor-pointer bg-transparent block w-full px-1"
                                style={styles.p}
                                onChange={(e) => {
                                  setData((prev) => {
                                    const upd = { ...prev };
                                    upd.experience[index].details[i] =
                                      e.target.value;
                                    return upd;
                                  });
                                }}
                                value={detail}
                              />
                            )}

                            <button
                              className="absolute right-0 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
                              onClick={() => {
                                setData((prev) => ({
                                  ...prev,
                                  experience: prev.experience.map(
                                    (exp, expIndex) =>
                                      expIndex === index
                                        ? {
                                            ...exp,
                                            details: exp.details.filter(
                                              (_, ind) => ind !== i
                                            ),
                                          }
                                        : exp
                                  ),
                                }));
                              }}
                            >
                              <FaDeleteLeft
                                size={30}
                                className="text-red-500 hover:text-red-600 translate-x-8"
                              />
                            </button>
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
            )}
          </div>

          {/* Projects Section */}
          {Array.isArray(data?.projects) && data.projects.length > 0 && (
            <div
              id="template-projects"
              className="section"
              style={{ ...styles.section, position: "relative" }}
            >
              <ProjectHeader data={data} setData={setData} />
              <h2 style={styles.sectionH2}>Projects</h2>
              {data.projects.map((project, index) => (
                <div
                  className="project"
                  style={{ ...styles.project, position: "relative" }}
                  key={index}
                >
                  <ProjectContainer
                    data={data}
                    setData={setData}
                    index={index}
                  />
                  <h3
                    id={`template-projects-${index}-title`}
                    onClick={clickHandler}
                    className="hover:bg-primary/10 cursor-pointer"
                    style={styles.h3}
                  >
                    {project.title}{" "}
                    {project.year &&
                      project.year !== "not found" &&
                      `(${project.year})`}
                  </h3>
                  {Array.isArray(project?.description) &&
                    project.description.map((detail, i) => (
                      <div
                        className="relative group" // Add 'group' class for easy hover styling
                        key={i}
                      >
                        {difference &&
                        difference["projects"][index] &&
                        difference["projects"][index]["description"][i] &&
                        conflicts["projects"] === -1 ? (
                          <TextDiffRenderer
                            diffData={
                              difference["projects"][index]["description"][i]
                            }
                            accept={`projects-${index}-description-${i}`}
                            actionHandler={actionHandler}
                          />
                        ) : (
                          <TextareaAutosize
                            id={`template-projects-${index}-description-${i}`}
                            className="hover:bg-primary/10 cursor-pointer bg-transparent block w-full px-1"
                            value={detail}
                            onChange={(e) => {
                              setData((prev) => {
                                const upd = { ...prev };
                                upd.projects[index].description[i] =
                                  e.target.value;
                                return upd;
                              });
                            }}
                          />
                        )}

                        <button
                          className="absolute right-0 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
                          onClick={() => {
                            setData((prev) => ({
                              ...prev,
                              projects: prev.projects.map(
                                (project, projIndex) =>
                                  projIndex === index
                                    ? {
                                        ...project,
                                        description: project.description.filter(
                                          (_, ind) => ind !== i
                                        ),
                                      }
                                    : project
                              ),
                            }));
                          }}
                        >
                          <FaDeleteLeft
                            size={30}
                            className="text-red-500 hover:text-red-600 translate-x-8"
                          />
                        </button>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          )}

          {/* Optional Details Section */}
          {Array.isArray(data?.optional) && data.optional.length > 0 && (
            <div
              id="template-optional"
              className="section"
              style={{ ...styles.section, position: "relative" }}
            >
              <MdAddBox
                className="absolute right-0 top-0 text-green-500 hover:text-green-600 cursor-pointer"
                size={30}
                onClick={() => {
                  setData((prev) => {
                    return {
                      ...prev,
                      optional: [...prev.optional, ""],
                    };
                  });
                }}
              />
              <h2 style={styles.sectionH2}>Optionals</h2>
              {Array.isArray(data?.optional) &&
                data.optional.map((detail, index) => (
                  <div key={index} className="relative group">
                    <p
                      className="optional-details-description w-full"
                      style={styles.optionalDetailsDescription}
                    >
                      {conflicts &&
                      difference &&
                      conflicts["optional"] &&
                      difference["optional"][index] ? (
                        <TextDiffRenderer
                          diffData={difference["optional"][index]}
                          actionHandler={actionHandler}
                          accept={`optional-${index}`}
                        />
                      ) : (
                        <TextareaAutosize
                          id={`template-optional-${index}`}
                          value={detail}
                          onChange={(e) => {
                            setData((prev) => {
                              const upd = prev.optional;
                              upd[index] = e.target.value;
                              return {
                                ...prev,
                                optional: upd,
                              };
                            });
                          }}
                          className="hover:bg-primary/10 cursor-pointer w-full bg-transparent"
                        />
                      )}

                      <button
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
                        onClick={() => {
                          setData((prev) => ({
                            ...prev,
                            optional: prev.optional.filter(
                              (_, i) => i !== index
                            ),
                          }));
                        }}
                      >
                        <FaDeleteLeft
                          size={30}
                          className="text-red-500 hover:text-red-600 translate-x-8"
                        />
                      </button>
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplateTesing_2;

function ExperienceHeader({ setData }) {
  return (
    <div className="absolute top-0 right-0">
      <MdAddBox
        size={30}
        className="text-green-500 hover:text-green-600 cursor-pointer"
        onClick={() => {
          setData((prevFormData) => ({
            ...prevFormData,
            experience: [
              ...prevFormData.experience,
              {
                company: "Company",
                position: "position",
                duration: "duration",
                details: ["Add details"],
              },
            ],
          }));
        }}
      />
    </div>
  );
}

function ExperienceContainer({ index, setData }) {
  return (
    <div className="absolute right-0 top-0 flex gap-1">
      <button
        className="bg-green-500 px-1 text-white"
        onClick={() => {
          setData((prev) => ({
            ...prev,
            experience: prev.experience.map((exp, i) =>
              i === index ? { ...exp, details: [...exp.details, ""] } : exp
            ),
          }));
        }}
      >
        Add detail
      </button>
      <button
        className="bg-red-500 text-white px-1"
        onClick={() => {
          setData((prevFormData) => ({
            ...prevFormData,
            ["experience"]: prevFormData["experience"].filter(
              (_, i) => i !== index
            ),
          }));
        }}
      >
        Remove experience
      </button>
    </div>
  );
}

function ProjectHeader({ data, setData }) {
  return (
    <div className="absolute top-0 right-0">
      <MdAddBox
        size={30}
        className="text-green-500 hover:text-green-600 cursor-pointer"
        onClick={() => {
          setData((prevFormData) => ({
            ...prevFormData,
            projects: [
              ...prevFormData.projects,
              {
                title: "Project title",
                year: "project-duration",
                description: ["Add some descriptions"],
              },
            ],
          }));
        }}
      />
    </div>
  );
}

function ProjectContainer({ index, data, setData }) {
  return (
    <div className="absolute right-0 top-0 flex gap-1">
      <button
        className="bg-green-500 px-1 text-white"
        onClick={() => {
          setData((prev) => ({
            ...prev,
            projects: prev.projects.map((project, i) =>
              i === index
                ? { ...project, description: [...project.description, ""] }
                : project
            ),
          }));
        }}
      >
        Add description
      </button>
      <button
        className="bg-red-500 text-white px-1"
        onClick={() => {
          setData((prevFormData) => ({
            ...prevFormData,
            ["projects"]: prevFormData["projects"].filter(
              (_, i) => i !== index
            ),
          }));
        }}
      >
        Remove project
      </button>
    </div>
  );
}
