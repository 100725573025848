import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Box,
  Button,
  TextareaAutosize,
} from "@mui/material";
import { coverLetterPlaceholder } from "../assets/interviewInstructions";
import { Divider } from "./Login";
import { Loading } from "./InterviewRoom";
import { useSelector } from "react-redux";
import userData from "../services/userData";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";
import PreRenderSkeleton from "../components/PreRender";
import { MdDownload } from "react-icons/md";
import { FaCopy } from "react-icons/fa6";
const showdown = require("showdown");

function CoverLetter() {
  const [tone, setTone] = useState("Convincing");
  const [secondaryTone, setSecondaryTone] = useState("Formal");
  const converter = new showdown.Converter();

  const [jobTitle, setJobTitle] = useState("");
  const [company, setCompany] = useState("");
  const [target, setTarget] = useState("");
  const [name, setName] = useState("");

  const [personal, setPersonal] = useState("");

  const [parsing, setParsing] = useState(false);

  const [step, setStep] = useState(1);

  const [generatingLetter, setGeneratingLetter] = useState(false);

  const dynamic_id = useSelector((state) => state.GlobalState.dynamic_id);

  const [letter, setLetter] = useState(null);

  const handleChange = (event) => {
    setTone(event.target.value);
  };

  const generateCoverLetter = async () => {
    try {
      setGeneratingLetter(true);

      const data = {
        name,
        personal,
        recipient: target,
        company,
        jobTitle,
        tone: `${tone + (secondaryTone ? " and " + secondaryTone : "")}`,
      };

      const res = await userData.generateCoverLetter(data);

      setLetter(res.data.letter);
      console.log(res);
    } catch (err) {
      console.log(err);
    } finally {
      setGeneratingLetter(false);
    }
  };

  const digestCV = async () => {
    try {
      setParsing(true);
      const data = {
        dynamic_id: String(dynamic_id),
        key: `key: ${dynamic_id}`,
      };
      const res = await userData.digestCV(data);
      console.log(res);
      setPersonal(res.data?.text);
      toast.success("CV digested successfully");
    } catch (err) {
      console.log(err);
      toast.error("Error while digesting CV");
    } finally {
      setParsing(false);
    }
  };

  const copyTextHandler = async () => {
    const htmlContent = converter.makeHtml(letter);
    await navigator.clipboard.write([
      new ClipboardItem({
        "text/html": new Blob([htmlContent], { type: "text/html" }),
        "text/plain": new Blob([letter], { type: "text/plain" }),
      }),
    ]);
    toast.success("Letter copied to clipboard");
  };

  const [downloading, setDownloading] = useState(false);

  const downloadCoverLetter = async () => {
    try {
      setDownloading(true);
      const htmlLetter = converter.makeHtml(letter);
      const data = {
        htmlString: htmlLetter,
        string: "",
      };
      const response = await userData.htmlToPdfService(data);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "cover_letter.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.error("Error while downloading CV", err);
      toast.error("Error while downloading cover letter");
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div className="overflow-hidden pb-5 h-full w-full primary-shadow">
      <div
        style={{
          transform: `translateX(-${(step - 1) * 100}%)`,
        }}
        className="flex h-full w-full overflow-visible transition-all duration-500"
      >
        {/* Step 1 */}
        <div
          className={`px-5 flex-shrink-0 h-full w-[100%] flex flex-col items-center gap-11 py-8 opacity-0 ${
            step === 1 && "opacity-100"
          }`}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              width: "100%",
              maxWidth: 600,
              margin: "auto",
              mt: 4,
              p: 3,
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: 2,
              backgroundColor: "white",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="toneselectid-label">Primary tone</InputLabel>
              <Select
                labelId="toneselectid-label"
                id="toneselectid"
                value={tone}
                onChange={handleChange}
                label="Select a tone"
                sx={{
                  boxShadow: "0px -1px 0px inset rgba(221, 221, 221)",
                  backgroundColor: "rgb(250, 235, 215)",
                  color: "rgb(0, 0, 0)",
                  fontWeight: "bold",
                }}
              >
                {[
                  "Appreciative",
                  "Assertive",
                  "Awestruck",
                  "Candid",
                  "Casual",
                  "Cautionary",
                  "Compassionate",
                  "Convincing",
                  "Critical",
                  "Earnest",
                  "Enthusiastic",
                  "Formal",
                  "Funny",
                  "Humble",
                  "Humorous",
                  "Informative",
                  "Inspirational",
                  "Joyful",
                  "Passionate",
                  "Thoughtful",
                  "Urgent",
                  "Worried",
                ].map((toneOption) => (
                  <MenuItem key={toneOption} value={toneOption}>
                    {toneOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="toneselectid-label">Secondary tone</InputLabel>
              <Select
                labelId="toneselectid-label"
                id="toneselectid"
                value={secondaryTone}
                onChange={(e) => {
                  setSecondaryTone(e.target.value);
                }}
                label="Select a tone"
                sx={{
                  boxShadow: "0px -1px 0px inset rgba(221, 221, 221)",
                  backgroundColor: "rgb(250, 235, 215)",
                  color: "rgb(0, 0, 0)",
                  fontWeight: "bold",
                }}
              >
                {[
                  "Appreciative",
                  "Assertive",
                  "Awestruck",
                  "Candid",
                  "Casual",
                  "Cautionary",
                  "Compassionate",
                  "Convincing",
                  "Critical",
                  "Earnest",
                  "Enthusiastic",
                  "Formal",
                  "Funny",
                  "Humble",
                  "Humorous",
                  "Informative",
                  "Inspirational",
                  "Joyful",
                  "Passionate",
                  "Thoughtful",
                  "Urgent",
                  "Worried",
                ].map((toneOption) => (
                  <MenuItem key={toneOption} value={toneOption}>
                    {toneOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
              fullWidth
              required
            />
            <TextField
              label="Job Title"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              variant="outlined"
              fullWidth
              required
            />
            <TextField
              label="Company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              variant="outlined"
              fullWidth
              required
            />
            <TextField
              label="Recipient Name/Team/Pronouns, e.g. Hiring manager"
              value={target}
              onChange={(e) => setTarget(e.target.value)}
              variant="outlined"
              fullWidth
              required
            />
            <Button
              variant={"outlined"}
              onClick={() => {
                if (!name || !jobTitle || !company || !target) {
                  toast.error("Please fill all the fields");
                  return;
                }
                setStep(2);
              }}
            >
              Next
            </Button>
          </Box>
        </div>

        {/* Step 2 */}
        <div
          className={`w-full flex-shrink-0 opacity-0 ${
            step === 2 && "opacity-100"
          } h-full transition duration-300 flex flex-col items-center gap-5 py-12`}
        >
          <div>
            <p className="text-lg font-bold text-center">
              Personal information
            </p>
            <p className="text-secondary-text font-semibold text-sm text-center">
              Write something about you for personalized generation (e.g. Tell
              about your skills, experiences etc.)
            </p>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                width: "100%",
                maxWidth: 600,
                margin: "auto",
                mt: 4,
                p: 3,
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
                backgroundColor: "white",
              }}
            >
              <div className="flex flex-col gap-4">
                <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      mt: 1,
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius: "4px",
                      padding: "10px",
                      backgroundColor: "rgb(250, 250, 250)",
                    }}
                  >
                    <TextareaAutosize
                      id="company-textarea"
                      maxRows={7}
                      value={personal}
                      onChange={(e) => setPersonal(e.target.value)}
                      minRows={4}
                      placeholder={coverLetterPlaceholder}
                      style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        resize: "none",
                        background: "transparent",
                        fontFamily: "inherit",
                        fontSize: "16px",
                      }}
                      required
                    />
                  </Box>
                </FormControl>
                <Divider />
                {!parsing && (
                  <Button
                    onClick={digestCV}
                    variant="contained"
                    color={"success"}
                  >
                    Auto fill from CV
                  </Button>
                )}
                {parsing && (
                  <div className="flex gap-2 items-center justify-center">
                    <Loading />
                    <p className="text-sm font-semibold text-secondary">
                      Digesting your CV
                    </p>
                  </div>
                )}
              </div>
              <button
                onClick={() => {
                  if (!personal) {
                    toast.error("Please fill in your professional summary");
                    return;
                  }
                  generateCoverLetter();
                  setStep(3);
                }}
                disabled={parsing}
                className={` bg-secondary ${
                  !parsing && "hover:bg-secondary/70"
                } ${parsing && "bg-gray-500"} px-3 py-2 rounded-md text-white`}
              >
                Generate cover letter
              </button>
            </Box>
          </div>
        </div>

        {/* Step 3 */}
        <div
          className={`px-5 flex-shrink-0 h-full w-[100%] flex flex-col items-center gap-11 py-8 opacity-0 ${
            step === 3 && "opacity-100"
          }`}
        >
          <div className="px-16 w-full rounded-xl parimary-shadow wooden-background">
            {letter && (
              <div className="flex justify-end items-center gap-4 pr-24 ">
                {!downloading && (
                  <Button
                    onClick={downloadCoverLetter}
                    variant="contained"
                    className="flex gap-2 items-center"
                  >
                    <p>Download</p>
                    <MdDownload size={20} />
                  </Button>
                )}
                {downloading && (
                  <div className="flex items-center gap-2">
                    <Loading />
                    <p className="text-white font-semibold">Downloading...</p>
                  </div>
                )}
                <Button
                  onClick={copyTextHandler}
                  variant="contained"
                  color={"success"}
                  className="flex gap-2 items-center"
                >
                  <p>Copy</p>
                  <FaCopy size={18} />
                </Button>
              </div>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                width: "100%",
                minHeight: window.innerHeight,
                maxWidth: 600,
                margin: "auto",
                mt: 4,
                p: 3,
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
                backgroundColor: "white",
              }}
            >
              {letter && <ReactMarkdown children={letter}></ReactMarkdown>}
              {!letter && (
                <div>
                  <div className="mb-5">
                    <PreRenderSkeleton
                      width={300}
                      height={16}
                      numberOfRows={1}
                    />
                    <PreRenderSkeleton
                      width={200}
                      height={16}
                      numberOfRows={2}
                    />
                    <PreRenderSkeleton
                      width={300}
                      height={16}
                      numberOfRows={1}
                    />
                  </div>
                  <div className="mb-12">
                    <PreRenderSkeleton height={16} numberOfRows={8} />
                  </div>

                  <div>
                    <PreRenderSkeleton
                      height={16}
                      width={200}
                      numberOfRows={2}
                    />
                  </div>
                </div>
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoverLetter;
