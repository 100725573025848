import React, { useEffect, useState } from "react";

const ScoreBar = ({ score }) => {
  const [displayedScore, setDisplayedScore] = useState(score);

  useEffect(() => {
    if (score !== null) {
      const timeoutId = setTimeout(() => setDisplayedScore(score), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [score]);

  // Function to interpolate the color based on score
  const getScoreColor = (score) => {
    if (score <= 50) {
      // Interpolate between red and yellow for scores from 0 to 50
      const red = 255;
      const green = Math.floor((255 * score) / 50);
      return `rgb(${red},${green},0)`; // Transitioning from red to yellow
    } else {
      // Interpolate between yellow and green for scores from 51 to 100
      const red = Math.floor(255 - (255 * (score - 50)) / 50);
      const green = 255;
      return `rgb(${red},${green},0)`; // Transitioning from yellow to green
    }
  };

  return (
    <div className="w-full text-center my-2 border-2 p-3 rounded-lg border-dashed border-primary/20">
      {score !== null && (
        <>
          <div className="w-full h-6 bg-gray-200 rounded-full overflow-hidden">
            <div
              className="h-full transition-all duration-700 ease-in-out flex items-center"
              style={{
                width: `${displayedScore}%`,
                backgroundColor: getScoreColor(displayedScore),
              }}

            >
              <p className="text-xs text-start px-3 font-bold whitespace-nowrap">Overall score:{` ${score}`}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ScoreBar;
