import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { openFeedback } from "../GlobalRedux/Features/GlobalStateSlice";

function Feedback() {
  const data = [
    {
      label: "My CV",
      value: "build",
    },
    {
      label: "Get advice",
      value: "evaluate",
    },
    {
      label: "Improve CV",
      value: "improve",
    },
    {
      label: "Interview room",
      value: "interview",
    },
    {
      label: "Overall experience",
      value: "overall",
    },
  ];

  const dispatch = useDispatch();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 ">
      <Card className="p-3">
        <CardContent className="flex flex-col gap-8">
          <div>
            <p className="text-lg font-bold text-primary-bold underline">
              Feedback, please!
            </p>
            <p className="text-secondary-text font-semibold mt-4">
              Your feedback helps us to improve. Please select the section below
              and provide your feedback.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            {data.map((d, i) => {
              return (
                <button
                  className="primary-button"
                  onClick={() => dispatch(openFeedback(d.value))}
                  key={i}
                >
                  {d.label}
                </button>
              );
            })}
          </div>
        </CardContent>
      </Card>
      <div>
        <img src="/mountain_top.svg" />
      </div>
    </div>
  );
}

export default Feedback;
